import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { DevTool } from '@hookform/devtools';
import { Control } from 'react-hook-form';

import { IS_DEV_ENV } from '../env';

const RHFDevToolsCtx = createContext<
  Dispatch<SetStateAction<Control<any> | undefined>>
>(() => {});

export function useFormDevTools(control: Control<any>) {
  const setControl = useContext(RHFDevToolsCtx);

  useEffect(() => {
    setControl(control);
    return () => {
      setControl(undefined);
    };
  }, [control, setControl]);
}

function RHFDevToolsProvider({ children }: PropsWithChildren<{}>) {
  const [control, setControl] = useState<Control<any> | undefined>(undefined);

  return (
    <RHFDevToolsCtx.Provider value={setControl}>
      {children}
      {IS_DEV_ENV && control && <DevTool control={control} />}
    </RHFDevToolsCtx.Provider>
  );
}

export default RHFDevToolsProvider;
