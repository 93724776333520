import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { Box, IconButton, Typography } from '@mui/material';

import { useToggleColorMode } from '../externalProviders/AppThemeProvider';
import useDarkMode from '../hooks/useColorScheme';
import useSafeTranslation from '../hooks/useSafeTranslation';

function ColorModeToggle() {
  const { toggleColorMode } = useToggleColorMode();
  const { T } = useSafeTranslation();

  const isDarkMode = useDarkMode();

  return (
    <Box display='flex' alignItems='center' onClick={toggleColorMode}>
      <IconButton sx={{ p: 0 }}>
        {isDarkMode ? (
          <Brightness7Icon fontSize='small' />
        ) : (
          <Brightness4Icon fontSize='small' />
        )}
      </IconButton>
      <Typography ml={2}>{T('change theme')}</Typography>
    </Box>
  );
}

export default ColorModeToggle;
