import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useLayoutEffect,
  useState,
} from 'react';

import { APP_TITLE } from '../env';

type TPageTitleSuffixCtx = {
  setPageTitleSuffix: Dispatch<SetStateAction<string>>;
  pageTitleSuffix: string;
};

const PageTitleSuffixCtxInitValue: TPageTitleSuffixCtx = {
  setPageTitleSuffix: () => {},
  pageTitleSuffix: '',
};

export const PageTitleSuffixCtx = createContext<TPageTitleSuffixCtx>(
  PageTitleSuffixCtxInitValue
);

const PageTitleSuffixCtxProvider = ({ children }: PropsWithChildren<{}>) => {
  const [pageTitleSuffix, setPageTitleSuffix] = useState('');

  useLayoutEffect(() => {
    document.title = `${APP_TITLE} ${
      pageTitleSuffix ? `| ${pageTitleSuffix}` : ''
    } `;
  }, [pageTitleSuffix]);

  return (
    <PageTitleSuffixCtx.Provider
      value={{ pageTitleSuffix, setPageTitleSuffix }}
    >
      {children}
    </PageTitleSuffixCtx.Provider>
  );
};

export default PageTitleSuffixCtxProvider;
