import { useIsFetching, useQueryClient } from '@tanstack/react-query';

import {
  Refresh as RefreshIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';

import useIsMobile from '../hooks/useIsMobile';
import { useNetworkStatus } from '../hooks/useNetworkStatus';
import useSafeTranslation from '../hooks/useSafeTranslation';

function QueryReloader() {
  const isNetworkFetching = useIsFetching() > 0;
  const isOnline = useNetworkStatus();
  const { T } = useSafeTranslation();
  const queryClient = useQueryClient();
  const isMobile = useIsMobile();

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', mr: 1 }}>
      {isOnline ? (
        <>
          <CircularProgress
            sx={{
              color: 'inherit',
              opacity: isNetworkFetching ? 1 : 0,
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                queryClient.refetchQueries({ type: 'active' });
              }}
              sx={{ color: 'inherit' }}
              disabled={isNetworkFetching}
            >
              <RefreshIcon fontSize={isMobile ? 'large' : undefined} />
            </IconButton>
          </Box>
        </>
      ) : (
        <Tooltip title={T('you are currently offline')}>
          <IconButton color='warning'>
            <WarningIcon fontSize='large' />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

export default QueryReloader;
