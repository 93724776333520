import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

function InfoPageInBaseLayout({ children }: PropsWithChildren<{}>) {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='100%'
      >
        {children}
      </Box>
    </Box>
  );
}

export default InfoPageInBaseLayout;
