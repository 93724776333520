import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { ZodErrorMap, z } from 'zod';

import useSafeTranslation, { withTSafe } from './useSafeTranslation';

const localizedErrorMap: withTSafe<ZodErrorMap> = T => () => ({
  message: T('invalid value'),
});

function useLocalizedZodError() {
  const {
    i18n: { language },
  } = useTranslation();

  const { T } = useSafeTranslation();

  useEffect(() => {
    z.setErrorMap(localizedErrorMap(T));
  }, [language, T]);
}

export default useLocalizedZodError;
