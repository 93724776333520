import { Avatar as MuiAvatar, SxProps, Theme, Tooltip } from '@mui/material';
import { GridDensity } from '@mui/x-data-grid-pro';

import useColorHash from '../hooks/useColorHash';
import usePrivateUrl from '../hooks/usePrivateUrl';
import { extractNameInitials } from '../utils';

type AvatarProps = {
  name?: string;
  imgSrc?: string;
  density?: GridDensity;
  tooltipTitle?: string;
  sx?: SxProps<Theme>;
} & (
  | {
      bgcolor?: string;
      inferBgColor?: never;
      useCacheInferring?: never;
    }
  | {
      bgcolor?: never;
      inferBgColor?: boolean;
      // avoid computing on every render the inferred color
      useCacheInferring?: boolean;
    }
);

const avatarFontSize: { [density in GridDensity]: `${number}%` } = {
  comfortable: '90%',
  standard: '85%',
  compact: '70%',
};

function Avatar({
  name,
  imgSrc,
  density,
  tooltipTitle,
  bgcolor,
  inferBgColor,
  useCacheInferring,
  sx,
}: AvatarProps) {
  const dimension = density === 'compact' ? 25 : 35;

  const imgDataUrl = usePrivateUrl(imgSrc);

  const { hexColorHash } = useColorHash(useCacheInferring);

  return (
    <Tooltip title={tooltipTitle ?? ''}>
      <MuiAvatar
        src={imgDataUrl}
        sx={{
          height: dimension,
          width: dimension,
          fontSize: density ? avatarFontSize[density] : undefined,
          bgcolor: name && inferBgColor ? hexColorHash(name) : bgcolor,
          ...sx,
        }}
      >
        {name && extractNameInitials(name)}
      </MuiAvatar>
    </Tooltip>
  );
}

export default Avatar;
