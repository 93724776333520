import {
  PropsWithChildren,
  ReactElement,
  Ref,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { useLocalStorage } from '@mantine/hooks';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';

import { CssBaseline, Grow } from '@mui/material';
import {
  Localization as MuiLocalization,
  enUS as enMui,
  itIT as itMui,
} from '@mui/material/locale';
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import {
  enUS as enMuiDataGrid,
  itIT as itMuiDataGrid,
} from '@mui/x-data-grid-pro';
import { Localization as MuiDataGridLocalization } from '@mui/x-data-grid/utils/getGridLocalization';

import {
  ALLOW_THEME_CHANGE,
  APP_TITLE,
  PRIMARY_COLOR,
  PRIMARY_COLOR_DARK,
  SECONDARY_COLOR,
  SECONDARY_COLOR_DARK,
} from '../env';
import { regexTest } from '../utils';

const langToMuiDataGridLocale: { [lng: string]: MuiDataGridLocalization } = {
  it: itMuiDataGrid,
  en: enMuiDataGrid,
};

const langToMuiLocale: { [lng: string]: MuiLocalization } = {
  it: itMui,
  en: enMui,
};

const GrowTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

const COLOR_NO_ALPHA_REGEX = /^\s*#[0-9A-Fa-f]{6}\s*$/;

const addAlphaToHexColor = (color: string) =>
  regexTest(color, COLOR_NO_ALPHA_REGEX) ? `${color.trim()}FF` : color;

export const BASE_MUI_THEME_CONFIG: (
  useDarkMode: boolean
) => ThemeOptions = useDarkMode => ({
  palette: {
    primary: {
      main: addAlphaToHexColor(
        useDarkMode ? PRIMARY_COLOR_DARK ?? PRIMARY_COLOR : PRIMARY_COLOR
      ),
    },
    ...(useDarkMode
      ? {
          background: {
            default: '#252525',
            paper: '#252525',
          },
        }
      : {}),
    secondary: {
      main: addAlphaToHexColor(
        useDarkMode ? SECONDARY_COLOR_DARK ?? SECONDARY_COLOR! : SECONDARY_COLOR
      ),
    },
    mode: useDarkMode ? 'dark' : 'light',
  },
  components: {
    MuiDialog: {
      defaultProps: {
        TransitionComponent: GrowTransition,
      },
    },
    MuiCircularProgress: { defaultProps: { disableShrink: true } },
  },
});

const ToggleColorModeCtx = createContext({
  toggleColorMode: () => {},
});

export const useToggleColorMode = () => useContext(ToggleColorModeCtx);

const ColorScaleCtx = createContext<{ colorScale: (val: number) => string }>({
  colorScale: () => '',
});

export const useColorScale = (val: number) =>
  useContext(ColorScaleCtx).colorScale(val);

const MEDIA_QUERY_PREFER_DARK_SCHEMA = '(prefers-color-scheme: dark)';

// since in pars of the app (e.g. flowEditor) multiple MUIThemeProviders are used, this hooks gives the actual app theme, not the one used at the component level
export const useAppTheme = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const prefersDarkModeMediaQuery = window.matchMedia(
    MEDIA_QUERY_PREFER_DARK_SCHEMA
  ).matches;

  const [useDarkMode, setUseDarkMode] = useLocalStorage({
    key: `${APP_TITLE}-useDarkMode`,
    defaultValue: prefersDarkModeMediaQuery,
    getInitialValueInEffect: false,
  });

  const appTheme = useMemo(
    () =>
      createTheme(
        BASE_MUI_THEME_CONFIG(ALLOW_THEME_CHANGE && useDarkMode),
        langToMuiDataGridLocale[language.slice(0, 2)],
        langToMuiLocale[language.slice(0, 2)]
      ),
    [language, useDarkMode]
  );

  return {
    appTheme: appTheme,
    useDarkMode,
    setUseDarkMode,
  };
};

function AppThemeProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { appTheme: theme, setUseDarkMode } = useAppTheme();

  const toggleColorMode = useCallback(
    () => setUseDarkMode(prevMode => !prevMode),
    [setUseDarkMode]
  );

  const colorScale = useCallback(
    (val: number) =>
      chroma
        .scale([theme.palette.error.main, theme.palette.success.main])
        .domain([0, 100])(val)
        .hex(),
    [theme]
  );

  return (
    <ToggleColorModeCtx.Provider value={{ toggleColorMode }}>
      <ColorScaleCtx.Provider value={{ colorScale }}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          {children}
        </ThemeProvider>
      </ColorScaleCtx.Provider>
    </ToggleColorModeCtx.Provider>
  );
}

export default AppThemeProvider;
