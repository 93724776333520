import {
  Navigate,
  Outlet,
  useLocation,
  useMatches,
} from '@tanstack/react-location';

import { Button, Typography } from '@mui/material';

import LoginAsSelector from './components/LoginAsSelector';
import PrivatePageContainer from './components/PrivatePageContainer';
import WholePageLayout from './components/layouts/WholePageLayout';
import PermissionsGuard from './components/utils/PermissionsGuard';
import { useAuth } from './contexts/AuthCtx';
import useSafeTranslation from './hooks/useSafeTranslation';
import { CustomRoute, LOGIN_ROUTE_PATH as LOGIN_PATH } from './routes';

function PrivateOutlet() {
  const { user, logout } = useAuth();
  const { name } = user ?? {};
  const location = useLocation();
  const matches = useMatches();
  const { T } = useSafeTranslation();
  const requiredPermissions = (matches[matches.length - 1].route as CustomRoute)
    .requiredPermissions;
  const canAccessUi = true;
  // const canAccessUi = useCanAccessUI();

  return user === undefined ? null : user === null ? (
    <Navigate to={`${LOGIN_PATH}/?redirect=${location.current.href}`} replace />
  ) : canAccessUi ? (
    <PrivatePageContainer>
      <PermissionsGuard requiredPermissions={requiredPermissions} showMsg>
        <Outlet />
      </PermissionsGuard>
    </PrivatePageContainer>
  ) : (
    <WholePageLayout sx={{ position: 'relative' }}>
      <Typography variant='h6' py={4} align='center'>
        {T('hello')} {name}
        <br />
        <br />
        {T('zero-access-msg')}
      </Typography>
      <LoginAsSelector
        sx={{
          position: 'absolute',
          top: 10,
          py: 1.5,
          px: 2,
          border: '1px solid',
          borderRadius: 1.5,
          borderColor: 'primary.contrastText',
        }}
      />
      <Button
        variant='outlined'
        onClick={() => {
          logout();
        }}
      >
        {T('logout')}
      </Button>
    </WholePageLayout>
  );
}

export default PrivateOutlet;
