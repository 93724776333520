import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../contexts/AuthCtx';
import { API_URL_HOSTNAME } from '../env';
import { privateToDataUrl } from '../utils';

function usePrivateUrl(url?: string) {
  const { token } = useAuth();

  const { data: imgDataUrl } = useQuery(
    ['privateUrl', url ?? ''],
    () => {
      if (!url) return undefined;
      if (!url.includes(API_URL_HOSTNAME)) return url;
      return privateToDataUrl(url, token ?? '');
    },
    { enabled: !!url && !!token, refetchOnWindowFocus: false }
  );

  return imgDataUrl;
}

export default usePrivateUrl;
