import { useState } from 'react';

import { Link } from '@tanstack/react-location';
import { Trans, useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { Alias } from '../api/resources/aliasResource';
import { useAuth } from '../contexts/AuthCtx';
import useIsMobile from '../hooks/useIsMobile';
import useSafeTranslation from '../hooks/useSafeTranslation';
import Avatar from './Avatar';
import AskConfirmDialog from './misc/AskConfirmDialog';

function LoginAsSelector({ sx }: { sx?: SxProps<Theme> }) {
  const { originalUser, actAs, originalToken, user, isActingAsAlias, aliases } =
    useAuth();
  const isMobile = useIsMobile();

  const { T } = useSafeTranslation();
  const { t } = useTranslation();

  const [onConfirmToken, setOnConfirmToken] = useState<string | undefined>(
    undefined
  );

  if (!aliases || aliases.length <= 1) return null;

  const handleChange = (ev: SelectChangeEvent<string>) => {
    const newId = ev.target.value;

    const newToken =
      newId === originalUser?.id
        ? originalToken
        : aliases.find(alias => alias.id === newId)?.token;
    if (newToken) setOnConfirmToken(newToken);
  };

  const aliasOptions: (Alias | 'divider')[] = [
    ...aliases.filter(alias => alias.name === originalUser?.name),
    'divider',
    ...aliases.filter(alias => alias.name !== originalUser?.name),
  ];

  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      flexDirection={isMobile ? 'column' : undefined}
      sx={sx}
    >
      <AskConfirmDialog
        open={!!onConfirmToken}
        onClose={() => {
          setOnConfirmToken(undefined);
        }}
        onConfirm={() => {
          actAs(onConfirmToken!);
          setOnConfirmToken(undefined);
        }}
        title=''
        color='warning'
      >
        <Typography mt={1} mb={3}>
          <Trans
            t={t}
            i18nKey='log-in-as-confirm'
            values={{
              name:
                aliases.find(alias => alias.token === onConfirmToken)?.name ??
                originalUser?.name,
            }}
            components={{
              1: <b />,
            }}
          />
        </Typography>
      </AskConfirmDialog>
      {isActingAsAlias ? (
        <>
          <Typography align='right'>
            {T('acting as')} {user?.name}
          </Typography>
          {!isMobile && (
            <Link to={`/users/${user?.id}`}>
              <Avatar name={user?.name} imgSrc={user?.picSrc} />
            </Link>
          )}
          <Button
            variant='contained'
            color='warning'
            onClick={() => setOnConfirmToken(originalToken ?? undefined)}
          >
            {T('go back to')} {originalUser?.name}
          </Button>
        </>
      ) : (
        <>
          <Typography align='right'>{T('act as')}</Typography>
          {!isMobile && (
            <Link to={`/users/${user?.id}`}>
              <Avatar name={user?.name} imgSrc={user?.picSrc} />
            </Link>
          )}

          <Select
            sx={{
              color: isActingAsAlias
                ? theme => `2px solid ${theme.palette.error.contrastText}`
                : 'primary.contrastText',
              border: isActingAsAlias
                ? theme => `2px solid ${theme.palette.error.main}`
                : '1px solid #FFFFFF',
              backgroundColor: isActingAsAlias
                ? theme => `${theme.palette.error[theme.palette.mode]}`
                : 'primary.main',
              '.MuiSelect-icon': {
                color: theme => theme.palette.primary.contrastText,
              },
              '.MuiSelect-select': {
                color: theme => theme.palette.primary.contrastText,
                padding: 1,
                ':focus': {
                  backgroundColor: 'transparent !important',
                },
              },
              '::before': {
                borderBottom: 'none !important',
              },
              '::before:hover': {
                borderBottom: 'none !important',
              },
            }}
            variant='outlined'
            value={user?.id ?? originalUser?.id}
            onChange={handleChange}
          >
            {aliasOptions.map((alias, i) =>
              alias === 'divider' ? (
                <Box width='100%' bgcolor='divider' height={2} my={1} key={i} />
              ) : (
                <MenuItem value={alias.id} key={i} disabled={!alias.token}>
                  {alias.name}
                </MenuItem>
              )
            )}
          </Select>
        </>
      )}
    </Box>
  );
}

export default LoginAsSelector;
