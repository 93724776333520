import { CSSProperties, useEffect } from 'react';

import { userApiResourceContract } from '../api/resources/userResource';
import { useAuth } from '../contexts/AuthCtx';
import useSingleQuery from '../hooks/queries/useSingleQuery';
import usePrivateUrl from '../hooks/usePrivateUrl';

function TenantImage({ style }: { style?: CSSProperties }) {
  const { user } = useAuth();

  const { isBusiness, picSrc, businessId } = user!;

  const tenantImgSrc = isBusiness ? picSrc : undefined;
  const { data: businessUser } = useSingleQuery(
    {
      apiResourceContract: userApiResourceContract,
      endpointArgs: {
        id: businessId ?? 'no-business',
      },
    },
    {
      enabled: !!businessId && !picSrc,
    }
  );

  const localImgSrc = usePrivateUrl(tenantImgSrc ?? businessUser?.picSrc);

  useEffect(() => {
    if (localImgSrc) {
      // FIXME not the best solution for the favicon
      const favicon = document.getElementById('favicon');
      favicon?.setAttribute('href', localImgSrc);
    }
  }, [localImgSrc]);

  if (!localImgSrc) return null;

  return (
    <img
      style={{ borderRadius: 4, ...style }}
      src={localImgSrc}
      alt="Organization's logo"
    />
  );
}

export default TenantImage;
