import { useEffect, useState } from 'react';

import { setHeaderProcessor } from '../api/api';

function useApiVersion() {
  const [apiVersion, setApiVersion] = useState<string | undefined>(undefined);

  useEffect(() => {
    setHeaderProcessor('api-version', setApiVersion);
  }, []);

  return apiVersion;
}

export default useApiVersion;
