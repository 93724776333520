import { usePrompt } from '@tanstack/react-location';

import { IS_DEV_ENV } from '../env';
import useSafeTranslation from './useSafeTranslation';

function usePreventClose(shouldPrevent: boolean) {
  const { T } = useSafeTranslation();

  usePrompt(T('leave-page-prompt'), !IS_DEV_ENV && shouldPrevent);
}

export default usePreventClose;
