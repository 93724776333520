import axios, { AxiosError } from 'axios';

import { LOCAL_KEY_AUTH_SSO_ID_TOKEN } from '../contexts/AuthCtx';
import { API_URL, AUTH_URL, IS_DEV_ENV } from '../env';
import { JSONValue, isArray } from '../utils';
import { ApiConverter } from './apiResourceContract';

const ID_TOKEN_HEADER_KEY = 'X-Id-Token';

type ApiResList<ApiType> = {
  total_rows?: number;
  rows?: { id: string; key: string; value: ApiType }[];
};

export type PagedData<ApiType> = {
  count: number;
  values: ApiType[];
};

export const apiClient = axios.create({
  baseURL: API_URL,
  headers: IS_DEV_ENV
    ? {
        // 'Bypass-Tunnel-Reminder': true,
        // 'ngrok-skip-browser-warning': true,
      }
    : undefined,
});

type HeaderProcessor = (headerValue: string) => void;
const resHeaderProcessors = new Map<string, HeaderProcessor>();

apiClient.interceptors.request.use(req => {
  if (AUTH_URL) {
    if (req.headers) {
      const token = (req.headers['common'] as any).token;
      const idToken = localStorage.getItem(LOCAL_KEY_AUTH_SSO_ID_TOKEN);

      if (token && idToken) {
        req.headers['Authorization'] = `Bearer ${token}`;
        req.headers[ID_TOKEN_HEADER_KEY] = idToken;

        delete (req.headers['common'] as any).token;
        delete req.headers['token'];
      }
    }
  }
  return req;
});

apiClient.interceptors.response.use(res => {
  Object.keys(res.headers).forEach(header => {
    resHeaderProcessors.get(header)?.(res.headers[header]);
  });
  return res;
});

export const setHeaderProcessor = (
  header: string,
  callback: HeaderProcessor
) => {
  resHeaderProcessors.set(header, callback);
};

const catchApiError = (err: AxiosError<{ error?: string; message?: any }>) => {
  if (err.response?.data.error && err.response?.data.message) {
    const msg = err.response?.data.message;
    throw new Error(typeof msg === 'string' ? msg : JSON.stringify(msg));
  }
  throw new Error(err.message);
};

export type FeFieldToApiField<ApiType, FeType> = {
  [key in keyof FeType]?: keyof ApiType;
};

export const setApiClientToken = (newToken: string | null) => {
  if (newToken) apiClient.defaults.headers.common['token'] = newToken;
  else delete apiClient.defaults.headers.common['token'];
};

interface ApiFilterItem {
  field: string;
  value: string;
}

interface PaginationParams {
  count: number;
  offset: number;
}
export interface SearchParam<ApiType = never> {
  field: keyof ApiType;
  value: string[] | string | boolean | undefined;
}

interface SortItem<ApiType> {
  field: keyof ApiType;
  ascending: boolean;
}
export interface GetListOptionsApi<ApiType> {
  pagination?: PaginationParams;
  sorting?: SortItem<ApiType> | undefined;
  searchParams?: SearchParam<ApiType>[];
}

interface ApiCallBaseConfig {
  endpoint: string;
  signal?: AbortSignal;
  headers?: Record<string, string>;
  token?: string;
}

interface ApiGetConfig extends ApiCallBaseConfig {
  filters?: ApiFilterItem[];
  urlSearchParams?: URLSearchParams;
}

export const apiGet = async <ApiType = unknown, FeType = unknown>(
  config: ApiGetConfig,
  convertFn: ApiConverter<ApiType, FeType>
): Promise<FeType> => {
  const { endpoint, headers, filters, urlSearchParams, signal, token } = config;

  const params = new URLSearchParams();
  if (filters) {
    filters.forEach(({ field, value }) => {
      params.append(`filters[${field}]`, `${value}`);
    });
  }
  if (urlSearchParams) {
    urlSearchParams.forEach((value, key) => {
      params.append(key, value);
    });
  }
  try {
    const res = await apiClient.get<ApiType>(
      `${endpoint}?${params
        .toString()
        // hacks because from the browser, requests with these character do not get interpreted well by the api (with Postman it works)
        .replaceAll('%5B', '[')
        .replaceAll('%5D', ']')}`,
      {
        headers: token ? { ...headers, token } : headers,
        signal,
      }
    );

    return await convertFn(res.data)!;
  } catch (err) {
    return catchApiError(err as any);
  }
};

interface ApiGetListConfig<ApiType> extends ApiGetConfig {
  listOptions?: GetListOptionsApi<ApiType>;
}

const listConverter = async <ApiType = unknown, FeType = unknown>(
  apiList: ApiResList<ApiType>,
  converterFn: ApiConverter<ApiType, FeType>
): Promise<PagedData<FeType>> => {
  const values = await Promise.allSettled(
    apiList.rows?.map(cl => {
      // need to use this workaround because promise.allSettled works properly only when the functions are actual promises
      // while TS considers a Promise also a normal function.
      // if we handle the error in this way the allSettled works as expected, instead of throwing another error
      try {
        return converterFn(cl.value);
      } catch (err) {
        return Promise.reject(err);
      }
    }) ?? []
  );

  return {
    count: apiList.total_rows ?? 0,
    values: values
      .filter(
        val =>
          (val as PromiseFulfilledResult<FeType>).value !== null &&
          val.status !== 'rejected'
      )
      .map(val => (val as PromiseFulfilledResult<FeType>).value) as FeType[],
  };
};

export function apiGetList<ApiType = unknown, FeType = unknown>(
  config: ApiGetListConfig<ApiType>,
  convertFn: ApiConverter<ApiType, FeType>
): Promise<PagedData<FeType>> {
  const { endpoint, headers, listOptions, urlSearchParams, signal, token } =
    config;
  let paginationUrl = '';
  const params = urlSearchParams ?? new URLSearchParams();
  if (listOptions) {
    const { sorting, searchParams, pagination } = listOptions;
    if (pagination) {
      const { offset, count } = pagination;
      paginationUrl += `${offset}/${count}`;
    }

    if (sorting) {
      params.append('filters[order]', `${sorting.field.toString()}`);
      params.append(
        'filters[orderDirection]',
        `${sorting.ascending ? 'ASC' : 'DESC'}`
      );
    }
    searchParams
      ?.filter(searchVal => searchVal.value !== '')
      .forEach(search => {
        if (isArray(search.value)) {
          (search.value as string[]).forEach(elm => {
            params.append(`filters[${search.field.toString()}][]`, `${elm}`);
          });
        } else
          params.append(
            `filters[${search.field.toString()}]`,
            `${search.value}`
          );
      });
  }

  return apiGet<ApiResList<ApiType>, PagedData<FeType>>(
    {
      endpoint: `${endpoint}${paginationUrl}`,
      headers,
      urlSearchParams: params,
      signal,
      token,
    },
    res => listConverter<ApiType, FeType>(res, convertFn)
  );
}

interface ApiPostConfig extends ApiCallBaseConfig {
  body?: Object;
  token?: string;
  urlSearchParams?: URLSearchParams;
}

interface ApiDeleteConfig extends ApiCallBaseConfig {
  body?: JSONValue;
}

interface PostResponseGeneric {
  id: number;
  ok: boolean;
}

interface PostResponse<ApiType = unknown> extends PostResponseGeneric {
  body: ApiType;
  ok: boolean;
}

// ApiTypes defaults to unknown to make it required
export function apiPost<RetType = unknown>(
  config: ApiPostConfig
): Promise<RetType> {
  const { endpoint, body, urlSearchParams, signal, headers } = config;
  return apiClient
    .post<RetType>(endpoint, body, {
      headers: {
        'Content-Type':
          body instanceof FormData ? 'multipart/form-data' : 'application/json',
        ...headers,
      },
      params: urlSearchParams,
      signal,
    })
    .then(res => res.data)
    .catch(catchApiError);
}

// ApiTypes defaults to unknown to make it required
export function apiPut<RetType = unknown>(
  config: ApiPostConfig
): Promise<RetType> {
  const { endpoint, body, urlSearchParams, signal } = config;
  return apiClient
    .put<RetType>(endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: urlSearchParams,
      signal,
    })
    .then(res => res.data)
    .catch(catchApiError);
}

export function createApiResource<
  CreateApiType extends Object | undefined = undefined,
  ApiType = unknown
>(endpoint: string, createData: CreateApiType): Promise<void> {
  return apiPost<PostResponse<ApiType>>({
    endpoint,
    body: createData,
  }).then(resBody => {
    if (resBody.ok === false)
      throw new Error(
        `Create on ${endpoint} was not successful, with body: ${JSON.stringify(
          createData
        )}`
      );
    return;
  });
}

export function updateApiResource<
  UpdateApiType extends Object | undefined = undefined
>(endpoint: string, updateData: UpdateApiType): Promise<void> {
  return apiPost<PostResponseGeneric>({
    endpoint,
    body: updateData,
  }).then(resBody => {
    if (resBody.ok === false)
      throw new Error(
        `Update on ${endpoint} was not successful, with body: ${JSON.stringify(
          updateData
        )}`
      );
    return;
  });
}

type DeleteResponse = { body: null; id: string; ok: true };

export function apiDelete(config: ApiDeleteConfig) {
  const { endpoint, body } = config;
  // TODO parse API error response, but before need to discover how api handles these kind of errors
  return apiClient.delete<DeleteResponse>(endpoint, { data: body });
}

type FileUploadResponse = {
  hash: string;
};

export function uploadFile(file: File, createDocument: boolean = false) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('filename', file.name);
  if (createDocument) formData.append('create_document', 'true');
  return apiPost<FileUploadResponse>({
    endpoint: `${API_URL}/file/mimeupload`,
    body: formData,
  });
}
