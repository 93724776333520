import { useEffect } from 'react';

import toast from 'react-hot-toast';

import { useNetworkStatus } from '../hooks/useNetworkStatus';
import useSafeTranslation from '../hooks/useSafeTranslation';

let lastToastId: string | undefined = undefined;

function NetworkOfflineToast() {
  const isOnline = useNetworkStatus();

  const { T } = useSafeTranslation();

  useEffect(() => {
    if (!isOnline)
      lastToastId = toast.error(T('you are currently offline'), {
        duration: Infinity,
        position: 'top-center',
      });
    else {
      if (lastToastId)
        toast.success(T('you are back online!'), {
          duration: 2000,
          id: lastToastId,
        });
      lastToastId = undefined;
    }
  }, [isOnline, T]);

  return null;
}

export default NetworkOfflineToast;
