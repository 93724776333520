import { useQuery } from '@tanstack/react-query';

import { GridSortModel } from '@mui/x-data-grid-pro';

import {
  FeFieldToApiField,
  GetListOptionsApi,
  PagedData,
  SearchParam,
  apiGetList,
} from '../../api/api';
import ApiResourceContract, {
  ApiConverter,
  ApiResourceContractOverride,
  overrideContract,
} from '../../api/apiResourceContract';
import { WithId } from '../../utils';

export function listQueryFn<ApiType, FeType extends WithId<{}>, EndpointArgs>({
  listOptions,
  listEndpoint,
  apiConverter,
  urlSearchParams,
  endpointArgs,
  token,
}: {
  listOptions?: GetListOptionsApi<ApiType>;
  listEndpoint: (args: EndpointArgs) => string;
  apiConverter: ApiConverter<ApiType, FeType>;
  urlSearchParams?: string[][];
  endpointArgs: EndpointArgs;
  token?: string;
}) {
  return ({ signal }: { signal?: AbortSignal } | undefined = {}) =>
    apiGetList<ApiType, FeType>(
      {
        endpoint: listEndpoint(endpointArgs),
        listOptions,
        urlSearchParams: new URLSearchParams(urlSearchParams ?? []),
        signal,
        token,
      },
      apiConverter
    );
}

type UseListQueryArgs<
  ApiType,
  FeType extends WithId<{}>,
  CreateResourceApi,
  CreateResourceFe,
  UpdateResourceApi,
  UpdateResourceFe extends WithId<{}>,
  EndpointArgs
> = {
  apiResourceContract?: ApiResourceContract<
    ApiType,
    FeType,
    CreateResourceApi,
    CreateResourceFe,
    UpdateResourceApi,
    UpdateResourceFe,
    EndpointArgs
  >;
  overrideApiResourceContract?: ApiResourceContractOverride<
    ApiType,
    FeType,
    CreateResourceApi,
    CreateResourceFe,
    UpdateResourceApi,
    UpdateResourceFe,
    EndpointArgs
  >;
  additionalUrlSearchParams?: string[][];
  sortModel?: GridSortModel;
  searchValues?: SearchParam<ApiType>[];
  pagination?: {
    pageSize: number;
    currentPage: number;
  };
  endpointArgs?: EndpointArgs;
  token?: string;
  additionalFeFieldToApiField?: Record<string, string>;
};
interface UseListQueryOptions<FeType> {
  enabled?: boolean;
  onSettled?: (data: PagedData<FeType> | undefined, error: unknown) => void;
  onSuccess?: (data: PagedData<FeType> | undefined) => void;
  structuralSharing?: boolean;
}

export function sortModelToApiSort<ApiType, FeType>(
  sortModel: GridSortModel,
  feFieldToApiField?: FeFieldToApiField<ApiType, FeType>
) {
  if (sortModel.length === 0) return undefined;
  if (
    sortModel[0]?.field &&
    feFieldToApiField?.[sortModel[0].field as keyof FeType]
  )
    return {
      field: feFieldToApiField[
        sortModel[0].field as keyof FeType
      ] as keyof ApiType,
      ascending: sortModel[0].sort === 'asc',
    };
  console.error(
    `CANNOT SORT LIST QUERY BECAUSE FIELD ${sortModel[0].field} IS NOT DEFINED IN feFieldToApiField `
  );
  return undefined;
}

export default function useListQuery<
  ApiType,
  FeType extends WithId<{}>,
  CreateResourceApi,
  CreateResourceFe,
  UpdateResourceApi,
  UpdateResourceFe extends WithId<{}>,
  EndpointArgs
>(
  {
    apiResourceContract,
    additionalUrlSearchParams,
    pagination,
    sortModel,
    searchValues,
    endpointArgs,
    token,
    overrideApiResourceContract,
    additionalFeFieldToApiField,
  }: UseListQueryArgs<
    ApiType,
    FeType,
    CreateResourceApi,
    CreateResourceFe,
    UpdateResourceApi,
    UpdateResourceFe,
    EndpointArgs
  >,
  options?: UseListQueryOptions<FeType>
) {
  const { listEndpoint, apiConverter, feFieldToApiField, baseUrlSearchParams } =
    apiResourceContract
      ? overrideContract(apiResourceContract, overrideApiResourceContract)
      : {
          listEndpoint: () => '',
          apiConverter: (() => {}) as unknown as ApiConverter<ApiType, FeType>,
          feFieldToApiField: {} as FeFieldToApiField<ApiType, FeType>,
          baseUrlSearchParams: [] as string[][],
        };

  const listOptions: GetListOptionsApi<ApiType> = {
    sorting:
      sortModel &&
      sortModelToApiSort(sortModel, {
        ...feFieldToApiField,
        ...additionalFeFieldToApiField,
      }),
    pagination: pagination && {
      offset: pagination.currentPage * pagination.pageSize,
      count: pagination.pageSize,
    },
    searchParams: searchValues ?? [],
  };

  const urlSearchParams = baseUrlSearchParams
    ? baseUrlSearchParams.concat(additionalUrlSearchParams ?? [])
    : additionalUrlSearchParams;

  const { enabled, onSettled, onSuccess, structuralSharing } = options ?? {};

  const queryKey = [
    listEndpoint(endpointArgs as EndpointArgs),
    { urlSearchParams, ...(pagination ?? {}), sortModel, searchValues, token },
  ];

  const query = useQuery(
    queryKey,
    listQueryFn({
      listEndpoint,
      listOptions,
      apiConverter,
      endpointArgs: endpointArgs as EndpointArgs,
      urlSearchParams,
      token,
    }),
    {
      enabled: apiResourceContract === undefined ? false : enabled,
      keepPreviousData: pagination ? true : undefined,
      onSettled,
      onSuccess,
      structuralSharing,
    }
  );
  return { ...query, queryKey };
}
