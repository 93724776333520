import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useLocalStorage } from '@mantine/hooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UserManager } from 'oidc-client-ts';
import { Trans, useTranslation } from 'react-i18next';

import { Box, CircularProgress, Typography } from '@mui/material';

import { setApiClientToken } from '../api/api';
import {
  Alias,
  aliasApiResourceContract,
} from '../api/resources/aliasResource';
import {
  getSelfUserUserApi,
  loginApiCall,
} from '../api/resources/authResource';
import { User } from '../api/resources/userResource';
import AskConfirmDialog from '../components/misc/AskConfirmDialog';
import { APP_TITLE, AUTH_CLIENT_ID, AUTH_URL, IS_DEV_ENV } from '../env';
import useListQuery from '../hooks/queries/useListQuery';
import { useActiveTabs } from '../hooks/useActiveTabs';
import useSafeTranslation from '../hooks/useSafeTranslation';

export const LOCAL_KEY_AUTH_TOKEN = `${APP_TITLE}_token`;
export const LOCAL_KEY_AUTH_ACTING_AS_TOKEN = `${APP_TITLE}_acting_as_token`;
export const LOCAL_KEY_AUTH_SSO_ID_TOKEN = `${APP_TITLE}_sso_id_token`;

type TAuthCtx = {
  // since in most of the user of the application will be the asLoggedIn user, for simplicity, the user is the asLoggedInUser and the original user is the default on with credentials
  user: undefined | null | User;
  originalUser: undefined | null | User;
  token: undefined | null | string;
  originalToken: undefined | null | string;
  login: (loginArgs: {
    email: string;
    psw: string;
    persistLocal: boolean;
  }) => Promise<void>;
  logout: () => void;
  actAs: (newToken: string) => void;
  isActingAsAlias: boolean | undefined;
  aliases?: Alias[];
};

const authCtxInitValue: TAuthCtx = {
  login: () => Promise.resolve(),
  logout: () => {},
  actAs: () => {},
  user: undefined,
  originalUser: undefined,
  token: undefined,
  originalToken: undefined,
  isActingAsAlias: undefined,
  aliases: undefined,
};

const AuthCtx = createContext<TAuthCtx>(authCtxInitValue);

export const useAuth = () => useContext(AuthCtx);

const SELF_USER_QUERY_KEY = 'self_user';

export const userManager = !!AUTH_URL
  ? new UserManager({
      authority: `${AUTH_URL}`,
      client_id: AUTH_CLIENT_ID ?? 'future-test',
      redirect_uri: window.location.origin,
      response_type: 'code',
      response_mode: 'query',
      popupWindowTarget: '_self',
      redirectMethod: 'replace',
      redirectTarget: 'self',
      automaticSilentRenew: true,
      post_logout_redirect_uri: window.location.origin,
    })
  : undefined;

function AuthCtxProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const queryClient = useQueryClient();

  const [persistTokenLocal, setPersistTokenLocal] = useState(
    !!localStorage.getItem(LOCAL_KEY_AUTH_TOKEN) ? true : false
  );

  const [originalToken, setOriginalToken] = useLocalStorage<
    string | undefined | null
  >({
    key: LOCAL_KEY_AUTH_TOKEN,
    defaultValue: undefined,
    getInitialValueInEffect: false,
  });
  const [token, setToken] = useLocalStorage<string | undefined | null>({
    key: LOCAL_KEY_AUTH_ACTING_AS_TOKEN,
    defaultValue: undefined,
    getInitialValueInEffect: false,
  });

  const activeTabs = useActiveTabs();

  useEffect(() => {
    const onWindowUnload = () => {
      if (!persistTokenLocal && activeTabs === 1) {
        localStorage.removeItem(LOCAL_KEY_AUTH_TOKEN);
        localStorage.removeItem(LOCAL_KEY_AUTH_ACTING_AS_TOKEN);
      }
    };

    window.addEventListener('unload', onWindowUnload);

    return () => {
      window.removeEventListener('unload', onWindowUnload);
    };
  }, [activeTabs, persistTokenLocal]);

  const queryToken = token ?? originalToken;

  useEffect(() => {
    if (token === originalToken) {
      setToken(null);
      // console.log('reset');
    }
  }, [originalToken, token, setToken]);

  useEffect(() => {
    if (queryToken) setApiClientToken(queryToken);
    else if (queryToken === null) setApiClientToken(null);
  }, [queryToken]);

  useEffect(() => {
    if (originalToken) {
      // Prevent overriding act as token
      if (!token) {
        setApiClientToken(originalToken);
      }
    }
  }, [setOriginalToken, token, originalToken]);

  const { data: originalUser } = useQuery(
    [SELF_USER_QUERY_KEY, originalToken],
    () => {
      if (originalToken) {
        return getSelfUserUserApi(originalToken).catch(err => {
          logout();
          console.error('ERROR WHEN CHECKING USER ACCOUNT', err);
          return null;
        });
      }
      return null;
    }
  );

  const [isChangingUser, setIsChangingUser] = useState(false);

  const { data: user } = useQuery(
    [SELF_USER_QUERY_KEY, token],
    () => {
      if (token) {
        return getSelfUserUserApi(token).catch(err => {
          logout();
          console.error('ERROR WHEN CHECKING USER ACCOUNT', err);
          return null;
        });
      }
      return null;
    },
    {
      keepPreviousData: true,
      // staleTime is set to 0 to trigger the onSettled every time the value changes
      staleTime: 0,
      onSettled: () => {
        if (token) {
          queryClient.removeQueries({ type: 'inactive' });
          queryClient
            .refetchQueries({
              predicate: query => !query.queryKey.includes(SELF_USER_QUERY_KEY),
            })
            .then(() => {
              setIsChangingUser(false);
            });
        } else {
          setIsChangingUser(false);
        }
      },
    }
  );

  const { mutateAsync: login } = useMutation(
    ({
      email,
      psw,
      persistLocal,
    }: {
      email: string;
      psw: string;
      persistLocal: boolean;
    }) => {
      setPersistTokenLocal(persistLocal);
      return loginApiCall(email, psw)
        .then(user => {
          queryClient.setQueryData([SELF_USER_QUERY_KEY, user.token], user);
          setOriginalToken(user.token);
        })
        .catch(err => {
          setOriginalToken(null);
          queryClient.setQueryData([SELF_USER_QUERY_KEY], null);
          console.error('ERROR WHEN TRYING TO LOGIN', err);
          throw err;
        });
    }
  );

  const actAs = (newToken: string) => {
    setToken(newToken);
    setIsChangingUser(true);
  };

  const logout = () => {
    queryClient.setQueryData([SELF_USER_QUERY_KEY], null);
    setOriginalToken(null);
    setToken(null);
    if (AUTH_URL) {
      localStorage.removeItem(LOCAL_KEY_AUTH_SSO_ID_TOKEN);
      userManager?.removeUser();
      userManager?.signoutPopup();
    }
  };

  useEffect(() => {
    if (userManager) {
      userManager.events.addUserLoaded(user => {
        const ssoToken = user.access_token ?? user.id_token;

        if (ssoToken) {
          setOriginalToken(ssoToken);
          // Prevent overriding act as token
          if (!token) {
            setApiClientToken(ssoToken);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOriginalToken, token]);

  const { T } = useSafeTranslation();
  const { t } = useTranslation();

  const isActingAsAlias = !!originalToken && !!token && originalToken !== token;

  const [open, setOpen] = useState(isActingAsAlias);

  const { data: aliases } = useListQuery(
    {
      apiResourceContract: aliasApiResourceContract,
      token: originalToken ?? 'not-supposed-to-make-this-call',
    },
    { enabled: !!originalToken }
  );

  return (
    <AuthCtx.Provider
      value={{
        originalUser,
        originalToken,
        user: user ?? originalUser,
        token: token ?? originalToken,
        logout,
        login,
        actAs,
        isActingAsAlias,
        aliases: aliases?.values,
      }}
    >
      {!IS_DEV_ENV && (
        <AskConfirmDialog
          open={open}
          onConfirm={() => setOpen(false)}
          onClose={() => {}}
          onCancel={() => {
            setToken(originalToken);
            setOpen(false);
            setIsChangingUser(true);
          }}
          title={T('warning')}
          color='warning'
          customLabels={{
            confirm: T('continue'),
            cancel: originalUser ? `${T('act as')} ${originalUser?.name}` : '',
          }}
        >
          {user && originalUser && (
            <Typography mb={3}>
              <Trans
                t={t}
                i18nKey='logged-as-other-warning'
                values={{
                  originalName: originalUser.name,
                  loggedInAsName: user.name,
                }}
                components={{
                  1: <b />,
                  2: <b />,
                }}
              />
            </Typography>
          )}
        </AskConfirmDialog>
      )}
      {isChangingUser && (
        <Box
          position='fixed'
          top={0}
          left={0}
          zIndex={9999}
          width='100vw'
          height='100vh'
          display='flex'
          justifyContent='center'
          alignItems='center'
          onClick={ev => {
            ev.preventDefault();
            ev.stopPropagation();
          }}
        >
          <CircularProgress color='primary' size='4em' />
          <Box
            position='fixed'
            top={0}
            left={0}
            width='100%'
            height='100%'
            bgcolor='primary.main'
            sx={{
              opacity: 0.2,
            }}
          />
        </Box>
      )}
      {children}
    </AuthCtx.Provider>
  );
}

export default AuthCtxProvider;
