import { WITH_ID_DEFAULT_TYPE, WithId } from '../utils';
import { FeFieldToApiField } from './api';

type ApiResourceContractEndpoints<EndpointArgs = {}> = {
  singleEndpoint: (args: EndpointArgs) => string;
  createEndpoint: (args: EndpointArgs) => string;
  updateEndpoint: (args: EndpointArgs) => string;
  listEndpoint: (args: EndpointArgs) => string;
  deleteSingleEndpoint: (args: EndpointArgs) => string;
};

// provide null as return value in case of an error during conversion (e.g. to filter out values that have not been successfully converted)
export type ApiConverter<ApiType, FeType> = (
  fromApi: ApiType
) => FeType | null | Promise<FeType>;

type ApiResourceContractBase<
  ApiType,
  FeType,
  CreateResourceApi,
  CreateResourceFe,
  UpdateResourceApi,
  UpdateResourceFe extends WithId<{}>
> = {
  name: string;
  apiConverter: ApiConverter<ApiType, FeType>;
  createConverter?: (
    feRes: CreateResourceFe
  ) => Promise<CreateResourceApi> | CreateResourceApi;
  updateConverter?: (
    feRes: UpdateResourceFe
  ) => Promise<UpdateResourceApi> | UpdateResourceApi;
  feFieldToApiField?: FeFieldToApiField<ApiType, FeType>;
  baseUrlSearchParams?: string[][];
};

type ApiResourceContract<
  ApiType,
  FeType extends WithId<{}>,
  CreateResourceApi = {},
  CreateResourceFe = {},
  UpdateResourceApi = {},
  // don't know why but I know that setting this default will byte me (or someone else) in the ass some day
  UpdateResourceFe extends WithId<{}> = WITH_ID_DEFAULT_TYPE,
  EndpointArgs = {}
> = ApiResourceContractBase<
  ApiType,
  FeType,
  CreateResourceApi,
  CreateResourceFe,
  UpdateResourceApi,
  UpdateResourceFe
> &
  ApiResourceContractEndpoints<EndpointArgs>;

export type ApiResourceContractOverride<
  ApiType,
  FeType extends WithId<{}>,
  CreateResourceApi = {},
  CreateResourceFe = {},
  UpdateResourceApi = {},
  UpdateResourceFe extends WithId<{}> = WITH_ID_DEFAULT_TYPE,
  EndpointArgs = {}
> =
  | Partial<
      ApiResourceContractBase<
        ApiType,
        FeType,
        CreateResourceApi,
        CreateResourceFe,
        UpdateResourceApi,
        UpdateResourceFe
      >
    > &
      Partial<ApiResourceContractEndpoints<EndpointArgs>>;

export default ApiResourceContract;

export function overrideContract<
  ApiType,
  FeType extends WithId<{}>,
  CreateResourceApi,
  CreateResourceFe,
  UpdateResourceApi,
  UpdateResourceFe extends WithId<{}> = WITH_ID_DEFAULT_TYPE,
  EndpointArgs = {}
>(
  base: ApiResourceContract<
    ApiType,
    FeType,
    CreateResourceApi,
    CreateResourceFe,
    UpdateResourceApi,
    UpdateResourceFe,
    EndpointArgs
  >,
  override?: ApiResourceContractOverride<
    ApiType,
    FeType,
    CreateResourceApi,
    CreateResourceFe,
    UpdateResourceApi,
    UpdateResourceFe,
    EndpointArgs
  >
): ApiResourceContract<
  ApiType,
  FeType,
  CreateResourceApi,
  CreateResourceFe,
  UpdateResourceApi,
  UpdateResourceFe,
  EndpointArgs
> {
  return {
    apiConverter: override?.apiConverter ?? base.apiConverter,
    createConverter: override?.createConverter ?? base.createConverter,
    updateConverter: override?.updateConverter ?? base.updateConverter,
    feFieldToApiField: override?.feFieldToApiField ?? base.feFieldToApiField,
    singleEndpoint: override?.singleEndpoint ?? base.singleEndpoint,
    createEndpoint: override?.createEndpoint ?? base.createEndpoint,
    updateEndpoint: override?.updateEndpoint ?? base.updateEndpoint,
    listEndpoint: override?.listEndpoint ?? base.listEndpoint,
    deleteSingleEndpoint:
      override?.deleteSingleEndpoint ?? base.deleteSingleEndpoint,
    name: override?.name ?? base.name,
    baseUrlSearchParams:
      override?.baseUrlSearchParams ?? base.baseUrlSearchParams,
  };
}
