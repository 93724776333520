import { PropsWithChildren } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { IS_DEV_ENV } from '../env';
import useIsMobile from '../hooks/useIsMobile';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !IS_DEV_ENV,
      staleTime: 22 * 1000,
      retry: IS_DEV_ENV ? false : 1,
    },
  },
});

function ReactQueryClientProvider(props: PropsWithChildren<{}>) {
  const isMobile = useIsMobile();
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      <ReactQueryDevtools
        initialIsOpen={false}
        position='bottom-right'
        toggleButtonProps={{ style: isMobile ? {} : { right: 40 } }}
      />
    </QueryClientProvider>
  );
}

export default ReactQueryClientProvider;
