import { Global, css } from '@emotion/react';
import { PropsWithChildren } from 'react';

import { Box, Paper, SxProps, Theme, Typography } from '@mui/material';

import { APP_TITLE, VERSION } from '../../env';

export const HtmlBodyRoot100 = () => (
  <Global
    styles={css`
      html,
      body,
      #app {
        height: 100%;
      }
    `}
  />
);

function WholePageLayout({
  children,
  sx,
}: PropsWithChildren<{ sx?: SxProps<Theme> }>) {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        bgcolor: 'primary.main',
        ...sx,
      }}
    >
      <HtmlBodyRoot100 />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='100%'
      >
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
            width: 'min(500px,90%)',
            borderRadius: 2,
          }}
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              bgcolor: 'secondary.main',
              textAlign: 'center',
              width: '100%',
              height: 50,
              borderRadius: 1,
              mb: 2,
            }}
            position='relative'
          >
            <Typography
              variant='h4'
              sx={{
                color: 'secondary.contrastText',
                letterSpacing: 1,
                fontSize: '1.7rem',
                fontWeight: 500,
              }}
              textTransform='uppercase'
            >
              {APP_TITLE}
            </Typography>
          </Box>
          {children}
          <Typography
            align='right'
            width='100%'
            color='primary'
            variant='body2'
          >
            v{VERSION}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
}

export default WholePageLayout;
