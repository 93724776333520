import { Global, css } from '@emotion/react';

import { useTranslation } from 'react-i18next';

import { Box, MenuItem, Select, Typography } from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

import { AVAIL_LANGUAGES } from '../env';

export const LANGUAGE_NAMES: { [key: string]: string } = {
  it: 'Italiano',
  en: 'English',
  fr: 'Français',
};

const LANGUAGE_SELECTOR_ID = 'language-selector';

function LanguageSelector({ sx }: { sx?: SxProps<Theme> }) {
  const {
    i18n: { language, changeLanguage },
  } = useTranslation();

  const theme = useTheme();
  return (
    <>
      {AVAIL_LANGUAGES.length > 1 && (
        <Box id={LANGUAGE_SELECTOR_ID} sx={sx}>
          <Global
            // This is  a workaround to color the border of the language selector in contrast color
            styles={css`
              #${LANGUAGE_SELECTOR_ID}
                fieldset.MuiOutlinedInput-notchedOutline {
                border-color: transparent !important;
              }
              #${LANGUAGE_SELECTOR_ID} .MuiOutlinedInput-root svg {
                color: ${theme.palette.primary.contrastText};
              }
            `}
          />
          {AVAIL_LANGUAGES.map(lang => (
            // this is to load all the language icons on page load instead of when opening the select menu
            <img
              key={lang}
              alt=''
              style={{ display: 'none' }}
              src={`https://unpkg.com/language-icons/icons/${lang}.svg`}
            />
          ))}
          <Select
            value={language.slice(0, 2)}
            onChange={e => {
              changeLanguage(e.target.value, err => {
                if (err) console.error('ERROR WHEN CHANGING LANGUAGE', err);
              });
            }}
            variant='outlined'
            sx={{
              width: '100%',
            }}
            SelectDisplayProps={{
              style: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 6,
              },
            }}
          >
            {AVAIL_LANGUAGES.map(lang => (
              <MenuItem
                value={lang}
                key={lang}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{
                    width: 20,
                    borderRadius: '50%',
                    marginRight: 8,
                  }}
                  alt={lang}
                  src={`https://unpkg.com/language-icons/icons/${lang}.svg`}
                />
                <Typography>{LANGUAGE_NAMES[lang]}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
    </>
  );
}

export default LanguageSelector;
