import { memo, useCallback, useState } from 'react';

import { Link } from '@tanstack/react-location';

import { Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useAuth } from '../contexts/AuthCtx';
import { APP_TITLE } from '../env';
import useIsMobile from '../hooks/useIsMobile';
import Avatar from './Avatar';
import LoginAsSelector from './LoginAsSelector';
import NavBar from './NavBar';
import { TOPBAR_HEIGHT } from './PrivatePageContainer';
import QueryReloader from './QueryReloader';
import TenantImage from './TenantImage';
import TopBarMenu from './TopBarMenu';

function TopBar() {
  const isMobile = useIsMobile();
  const [navBarDrawerOpen, setNavBarDrawerOpen] = useState(false);

  const onNavBarExpandedIconClick = useCallback(
    () => setNavBarDrawerOpen(false),
    []
  );
  const { originalUser, user, aliases, isActingAsAlias } = useAuth();

  const isMdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <AppBar
      sx={{
        backgroundColor: 'primary.main',
        height: TOPBAR_HEIGHT,
        justifyContent: 'center',
      }}
      elevation={0}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: isMobile ? 0 : undefined,
        }}
      >
        {isMobile && (
          <Drawer
            anchor='left'
            open={navBarDrawerOpen}
            onClose={() => setNavBarDrawerOpen(false)}
            disableScrollLock
          >
            <Box>
              <NavBar
                expanded
                fullHeight
                onExpandIconClick={onNavBarExpandedIconClick}
              />
            </Box>
          </Drawer>
        )}
        <Box display='flex' alignItems='center' height='100%' gap={0}>
          {isMobile && (
            <IconButton
              onClick={() => setNavBarDrawerOpen(true)}
              sx={{ mr: 1 }}
              color='inherit'
            >
              <MenuIcon fontSize='large' />
            </IconButton>
          )}
          <Link
            to='/'
            style={{ height: '100%', display: 'flex', alignItems: 'center' }}
          >
            <Typography variant='h5'>{APP_TITLE}</Typography>
            <TenantImage style={{ height: '50%', marginLeft: 10 }} />
          </Link>
        </Box>
        {!isMobile && <LoginAsSelector />}
        <Box display='flex' alignItems='center'>
          <QueryReloader />
          <Link to={`/users/${isMobile ? user?.id : originalUser?.id}`}>
            <Box display='flex' alignItems='center' gap={1}>
              {!!aliases && aliases.length > 0 && (
                <Avatar
                  imgSrc={(isMobile ? user : originalUser)?.picSrc}
                  sx={{
                    border:
                      isMobile && isActingAsAlias
                        ? theme => `4px solid ${theme.palette.error.main}`
                        : undefined,
                  }}
                />
              )}
              {!isMdScreen && <Typography>{originalUser?.name}</Typography>}
            </Box>
          </Link>
          <TopBarMenu />
          {/* {!isMobile && (
            <Typography align='right'>
              v{VERSION} {apiVersion && `| v${apiVersion}`}
            </Typography>
          )} */}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default memo(TopBar);
