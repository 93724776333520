import { ReactNode, isValidElement, useEffect } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import usePreventClose from '../../hooks/usePreventClose';
import useSafeTranslation from '../../hooks/useSafeTranslation';
import { ButtonAction } from '../../utils';
import { TOPBAR_HEIGHT } from '../PrivatePageContainer';
import { BtnColor } from './CustomButton';

type AskConfirmDialogCustomLabels = {
  confirm?: string;
  cancel?: string;
};

interface CustomDialogBaseProps {
  onClose: () => void;
  open: boolean;
  children?: ReactNode;
  title?: string;
  blockUserInteraction?: boolean;
  color?: BtnColor;
  fullScreen?: boolean;
  embedded?: boolean;
  customLabels?: AskConfirmDialogCustomLabels;
  customActions?: (ButtonAction | ReactNode)[];
  confirmDisabled?: boolean;
  sx?: SxProps<Theme>;
}

interface CustomDialogFormProps extends CustomDialogBaseProps {
  submitFormId?: string;
  onConfirm?: never;
  onCancel?: never;
}

interface CustomDialogActionProps extends CustomDialogBaseProps {
  submitFormId?: never;
  onConfirm: () => void;
  onCancel?: () => void;
}

type CustomDialogProps = CustomDialogFormProps | CustomDialogActionProps;

function AskConfirmDialog(props: CustomDialogProps) {
  const {
    onClose,
    open,
    children,
    submitFormId,
    title,
    onConfirm,
    blockUserInteraction,
    color,
    fullScreen,
    embedded,
    customActions,
    customLabels = {},
    confirmDisabled,
    sx,
    onCancel,
  } = props;
  const { T } = useSafeTranslation();
  const { confirm: confirmLabel, cancel: cancelLabel } = customLabels;
  usePreventClose(blockUserInteraction ?? false);

  // prevent scrolling background when dialog is open
  useEffect(() => {
    const onScroll = () => {
      document.documentElement.style.setProperty(
        '--scroll-y',
        `${window.scrollY}px`
      );
    };
    window.addEventListener('scroll', onScroll);

    if (open) {
      const scrollY =
        document.documentElement.style.getPropertyValue('--scroll-y') || '0';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}`;
      document.body.style.width = '100%';
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
      // these lines should be in the else of the if(open) but  there they don't work
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
  }, [open]);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          height: '100%',
          bgcolor: 'transparent',
          justifyContent: fullScreen ? 'flex-end' : undefined,
          ...sx,
        },
        elevation: 0,
      }}
      onClose={() => {
        onClose();
      }}
      fullWidth={!!submitFormId}
      maxWidth='xl'
      fullScreen={fullScreen}
      disableScrollLock
    >
      <Box
        bgcolor='background.paper'
        p={fullScreen ? 3 : 1}
        my={fullScreen ? undefined : 'auto'}
        position='relative'
        maxHeight={embedded ? '100%' : undefined}
        height={fullScreen ? `calc(100% - ${TOPBAR_HEIGHT})` : undefined}
        display='flex'
        flexDirection='column'
        borderRadius={fullScreen ? undefined : 3}
        border={
          fullScreen
            ? undefined
            : theme =>
                theme.palette.mode === 'dark'
                  ? `2px solid ${theme.palette.action.disabled}`
                  : undefined
        }
      >
        <Box
          display='flex'
          justifyContent='space-between'
          top={0}
          zIndex={10}
          position='sticky'
          width='100%'
          maxWidth='inherit'
          bgcolor='background.paper'
        >
          {title && (
            <Typography
              color={color ? `${color}.main` : 'primary'}
              variant='h5'
              py={2}
              px={2}
            >
              {title}
            </Typography>
          )}
          {blockUserInteraction && <CircularProgress />}
        </Box>
        <Box
          {...(embedded
            ? { maxHeight: '100%', sx: { overflowY: 'auto' } }
            : { height: fullScreen ? '100%' : undefined })}
        >
          {children && (
            <Box
              position='relative'
              sx={{
                pointerEvents: blockUserInteraction ? 'none' : undefined,
                opacity: blockUserInteraction ? 0.5 : undefined,
              }}
            >
              <Container
                maxWidth='xl'
                sx={{
                  pt: 1,
                }}
              >
                {children}
              </Container>
            </Box>
          )}
        </Box>
        <Box display='flex' gap={1.5} justifyContent='flex-end'>
          <Button
            disabled={blockUserInteraction}
            variant='outlined'
            onClick={onCancel ?? onClose}
            color={color}
          >
            {cancelLabel ?? T('cancel')}
          </Button>
          {customActions?.map((action, i) => {
            if (isValidElement(action)) return action;

            const { disabled, onClick, label, level, type, formId } =
              action as ButtonAction;
            return (
              <Button
                disabled={disabled}
                variant='outlined'
                onClick={onClick}
                color={level}
                key={i}
                type={type}
                form={formId}
              >
                {label}
              </Button>
            );
          })}
          <Button
            disabled={confirmDisabled || blockUserInteraction}
            variant='contained'
            type={submitFormId ? 'submit' : 'button'}
            form={submitFormId}
            onClick={onConfirm}
            color={color}
          >
            {confirmLabel ?? T('confirm')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default AskConfirmDialog;
