import fromUnixTime from 'date-fns/fromUnixTime';

import { AutocompleteOption } from '../../components/form/HookedAutocomplete';
import { AvailPermission } from '../../components/utils/PermissionsGuard';
import { isArray, typedKeys } from '../../utils';
import { uploadFile } from '../api';
import ApiResourceContract from '../apiResourceContract';
import {
  DbBoolNum,
  DbBoolStr,
  fromDbBool,
  toDbBoolNum,
  toDbBoolStr,
} from '../apiTypes';
import { fromUnixString } from '../apiUtils';
import {
  Permission,
  PermissionApi,
  fromPermissionApi,
} from './permissionResource';
import { Role } from './roleResource';

export enum UserStatuses {
  active = 'active',
  new = 'new',
  disabled = 'disabled',
}

export type UserStatus = keyof typeof UserStatuses;

export const uneditableUserFields = ['_id'];

interface ParentUserApi {
  _id: string;
  avatar_thumb_file_id: string | null;
  name: string;
  firstname: string;
  lastname: string;
  avatar_thumb_file_id_public_url: string | null;
}

export type MetaTags = { [group: string]: { [tag: string]: string | null } };

export interface UserApi extends ParentUserApi {
  email: string;
  last_login?: number;
  created: number | null;
  parent_id: string;
  parent_name: string;
  type: 'business' | string;
  agent_type: string;
  mobile: string | null;
  status: UserStatus;
  activated: string | null;
  external_id: string;
  tags?: { [tagId: string]: string };
  last_device_data: string | null;
  business_id: string | null;
  autoresponder?: DbBoolStr;
  autoresponder_body: string | null;
  permissions?: PermissionApi[];
  permissions_list?: { [id: number]: string };
  roles?: { name: string; pivot: { role_id: number } }[];
  specification: string | null;
  specificationRole: string | null;
  external_parent_id?: number | null;
  visibility_hierarchy: DbBoolNum;
  visibility_tag_id: number;
  visibility_tag_name: string;
  visibility_user_id: number;
  visibility_user_name: string;
  meta?: MetaTags | [];
  user_group?: { role: { id: string; name: string } };
  user_alias?: { _id: string; name: 'string' }[];
  is_testing_user: DbBoolNum | undefined | null;
  is_testing_home_user: DbBoolNum | undefined | null;
  is_local_account: DbBoolNum | undefined | null;
  main_role_id: number | null | undefined;
  provider_id: string | null;
  domain_id: string | null;
  domain_name: string | null;
  [key: string]: any;
}

export interface DeviceData {
  appVersion: string;
  osVersion: string;
  os: string;
  type: string;
  model: string;
  resolution: string;
}

interface ParentUser {
  id: string;
  name: string;
  lastname: string;
  firstname: string;
  picSrc?: string;
  picHash?: string;
  avatarThumbFileIdPublicUrl?: string;
}

export interface User extends ParentUser {
  email: string;
  lastLogin: Date | undefined;
  createdAt?: Date;
  parent: AutocompleteOption;
  isBusiness: boolean;
  // TODO check which are the possible values
  agentType: string;
  mobile?: string;
  status: UserStatus;
  activatedAt?: Date;
  externalId?: string;
  externalParentId?: string;
  tags: { id: string; name: string }[];
  deviceData: DeviceData | undefined;
  businessId?: string;
  specification?: string;
  specificationRole?: string;
  roles: Role[];
  permissions: Permission[];
  autoresponderActive?: boolean;
  autoresponderBody?: string;
  hasHierarchyVisibility?: boolean;
  visibilityTagId?: string;
  visibilityTagName?: string;
  visibilityUserId?: string;
  visibilityUserName?: string;
  meta: MetaTags;
  groupRole?: { id: string; name: string };
  aliases?: AutocompleteOption[];
  rawPermissions: AvailPermission[];
  isTestingUser: boolean;
  isTestingHomeUser: boolean;
  isLocalAccount: boolean;
  mainRoleId?: string;
  providerId?: string;
  domainId?: string;
  domainName?: string;
  [key: string]: any;
}

enum NotificationModes {
  mail = 'mail',
  both = 'both',
  sms = 'sms',
  none = 'none',
}

type NotificationMode = keyof typeof NotificationModes;

export type CreateUser = Pick<User, 'firstname'> &
  Partial<
    Pick<
      User,
      | 'lastname'
      | 'mobile'
      | 'email'
      | 'externalId'
      | 'specification'
      | 'specificationRole'
      | 'externalParentId'
      | 'hasHierarchyVisibility'
      | 'autoresponderActive'
      | 'autoresponderBody'
      | 'status'
      | 'aliases'
      | 'isTestingUser'
      | 'isTestingHomeUser'
      | 'isLocalAccount'
      | 'mainRoleId'
      | 'parent'
    >
  > & {
    visibilityTagId?: AutocompleteOption;
    visibilityUserId?: AutocompleteOption;
    sendInvitationEmail?: boolean;
    sendInvitationSms?: boolean;
    roles?: string[];
    pic?: File;
    picHash?: string;
    picSrc?: string;
    newMeta?: Record<string, string>;
    domainId?: AutocompleteOption;
  };

export type UpdateUser = Partial<CreateUser> & { id: string };
type UpdateUserApi = Partial<CreateUserApi>;

type CreateUserApi = Pick<UserApi, 'firstname'> &
  Partial<
    Pick<
      UserApi,
      | 'lastname'
      | 'specification'
      | 'specificationRole'
      | 'parent_id'
      | 'external_id'
      | 'external_parent_id'
      | 'visibility_hierarchy'
      | 'visibility_tag_id'
      | 'visibility_user_id'
      | 'mobile'
      | 'autoresponder'
      | 'autoresponder_body'
      | 'avatar_thumb_file_id'
      | 'status'
      | 'is_testing_user'
      | 'is_testing_home_user'
      | 'is_local_account'
      | 'main_role_id'
      | 'domain_id'
    >
  > & {
    email?: string;
    sendMailOrSMS: NotificationMode;
    roles: string[];
    user_alias?: string[];
    avatar_file_id?: string | null;
    meta?: Record<string, string>;
  };

const apiConvertParentUser = (usr: ParentUserApi): ParentUser => {
  const picHash = ['bgplaceholdernew', 'userplaceholder', ''].includes(
    usr.avatar_thumb_file_id ?? ''
  )
    ? undefined
    : usr.avatar_thumb_file_id;
  return {
    id: usr._id,
    name: usr.name,
    picHash: picHash ?? undefined,
    picSrc: usr.avatar_thumb_file_id_public_url ?? undefined,
    firstname: usr.firstname,
    lastname: usr.lastname,
  };
};

export const fromUserApi = (usr: UserApi): User => {
  let parsedDeviceData = undefined;

  if (usr.last_device_data) {
    try {
      parsedDeviceData = JSON.parse(usr.last_device_data);
      // because there is no interest in knowing parsing error because it is available on backend
      // eslint-disable-next-line no-empty
    } catch {}
  }

  return {
    ...usr,
    ...apiConvertParentUser(usr),

    email: usr.email,
    externalParentId: usr.external_parent_id?.toString(),
    lastLogin: usr.last_login ? fromUnixTime(usr.last_login) : undefined,
    createdAt: usr.created ? fromUnixTime(usr.created) : undefined,
    parent: { label: usr.parent_name ?? usr.parent_id, value: usr.parent_id },
    isBusiness: usr.type === 'business',
    agentType: usr.agent_type,
    mobile: usr.mobile ?? undefined,
    meta: usr.meta ? (isArray(usr.meta) ? {} : (usr.meta as MetaTags)) : {},
    status: usr.status,
    activatedAt: usr.activated ? fromUnixString(usr.activated) : undefined,
    externalId: usr.external_id ?? undefined,
    tags: typedKeys(usr.tags ?? {}).map(tagId => ({
      id: tagId as string,
      name: usr.tags![tagId],
    })),
    businessId: usr.business_id ?? undefined,
    deviceData: parsedDeviceData
      ? {
          appVersion: parsedDeviceData['app_version:'],
          model: parsedDeviceData.device_model,
          osVersion: parsedDeviceData.device_os_version,
          os: parsedDeviceData.device_os,
          type: parsedDeviceData.device_type,
          resolution: parsedDeviceData.device_resolution,
        }
      : undefined,
    roles:
      usr.roles?.map(({ name, pivot: { role_id } }) => ({
        name,
        id: role_id.toString(),
      })) ?? [],
    permissions: usr.permissions
      ?.map(permApi => fromPermissionApi(permApi.name))
      .filter(perm => perm !== undefined) as Permission[],
    rawPermissions:
      usr.permissions?.map(permApi => permApi.name as AvailPermission) ?? [],
    autoresponderActive: usr.autoresponder
      ? fromDbBool(usr.autoresponder)
      : undefined,
    autoresponderBody: usr.autoresponder_body ?? undefined,
    specification: usr.specification ?? undefined,
    specificationRole: usr.specificationRole ?? undefined,
    visibilityTagName: usr.visibility_tag_name ?? undefined,
    visibilityUserName: usr.visibility_user_name ?? undefined,
    hasHierarchyVisibility: fromDbBool(usr.visibility_hierarchy),
    visibilityTagId:
      !usr.visibility_tag_id || usr.visibility_tag_id === 0
        ? undefined
        : usr.visibility_tag_id.toString(),
    visibilityUserId:
      !usr.visibility_user_id || usr.visibility_user_id === 0
        ? undefined
        : usr.visibility_user_id.toString(),
    groupRole: usr.user_group?.role ?? { name: 'user', id: '101' },
    aliases: usr.user_alias?.map(({ _id, name }) => ({
      value: _id,
      label: name,
    })),
    isTestingUser: usr.is_testing_user
      ? fromDbBool(usr.is_testing_user)
      : false,
    isTestingHomeUser: usr.is_testing_home_user
      ? fromDbBool(usr.is_testing_home_user)
      : false,
    isLocalAccount: usr.is_local_account
      ? fromDbBool(usr.is_local_account)
      : false,
    mainRoleId: usr.main_role_id?.toString() ?? undefined,
    providerId: usr.provider_id?.toString() ?? undefined,
    domainId: usr.domain_id?.toString() ?? undefined,
    domainName: usr.domain_name?.toString() ?? undefined,
  };
};

export const userApiResourceContract: ApiResourceContract<
  UserApi,
  User,
  CreateUserApi,
  CreateUser,
  UpdateUserApi,
  UpdateUser,
  { id: string }
> = {
  singleEndpoint: ({ id }) => `user/${id}`,
  listEndpoint: () => 'user/list/',
  createEndpoint: () => 'user/invite',
  updateEndpoint: ({ id }) => `user/update/${id}`,
  deleteSingleEndpoint: ({ id }) => `user/${id}`,
  name: 'user',
  apiConverter: fromUserApi,
  feFieldToApiField: {
    id: '_id',
    email: 'email',
    name: 'name',
    parentId: 'parent_id',
    status: 'status',
    lastLogin: 'last_login',
    activatedAt: 'activated',
    createdAt: 'created',
  },
  createConverter: async usr => {
    const picUploadRes = usr.pic ? await uploadFile(usr.pic) : undefined;
    const picHash = picUploadRes?.hash;
    if (!usr.roles) throw new Error('New user roles are undefined');

    return {
      avatar_file_id: picHash,
      avatar_thumb_file_id: picHash,
      firstname: usr.firstname,
      lastname: usr.lastname ?? '',
      email: usr.email === '' ? undefined : usr.email,
      mobile: usr.mobile === '' ? undefined : usr.mobile,
      specification: usr.specification,
      specificationRole: usr.specificationRole,
      sendMailOrSMS: toInvitationModeApi(
        usr.sendInvitationEmail,
        usr.sendInvitationSms
      ),
      roles: usr.roles,
      parent_id: usr.parent?.value,
      external_id: usr.externalId,
      visibility_hierarchy: usr.hasHierarchyVisibility
        ? toDbBoolNum(usr.hasHierarchyVisibility)
        : undefined,
      visibility_tag_id: usr.visibilityTagId
        ? Number(usr.visibilityTagId.value)
        : undefined,
      visibility_user_id: usr.visibilityUserId
        ? Number(usr.visibilityUserId.value)
        : undefined,
      autoresponder: toDbBoolStr(usr.autoresponderActive ?? false),
      autoresponder_body: usr.autoresponderBody,
      status: 'new',
      user_alias: usr.aliases?.map(({ value }) => value),
      is_testing_user: toDbBoolNum(usr.isTestingUser ?? false),
      is_testing_home_user: toDbBoolNum(usr.isTestingHomeUser ?? false),
      is_local_account: toDbBoolNum(usr.isLocalAccount ?? false),
      meta: usr.newMeta,
      main_role_id: Number(usr.mainRoleId),
      domain_id: usr.domainId?.value,
    };
  },
  updateConverter: async usr => {
    const picHash =
      usr.picHash ?? (usr.pic ? (await uploadFile(usr.pic))?.hash : null);
    return {
      avatar_file_id: picHash,
      avatar_thumb_file_id: picHash,
      firstname: usr.firstname,
      lastname: usr.lastname,
      email: usr.email === '' ? undefined : usr.email,
      mobile: usr.mobile === '' ? undefined : usr.mobile,
      specification: usr.specification,
      specificationRole: usr.specificationRole,
      roles: usr.roles,
      parent_id: usr.parent?.value,
      external_id: usr.externalId,
      visibility_hierarchy:
        usr.hasHierarchyVisibility !== undefined &&
        usr.hasHierarchyVisibility !== null
          ? toDbBoolNum(usr.hasHierarchyVisibility)
          : undefined,
      visibility_tag_id: usr.visibilityTagId
        ? Number(usr.visibilityTagId.value)
        : undefined,
      visibility_user_id: usr.visibilityUserId
        ? Number(usr.visibilityUserId.value)
        : undefined,
      autoresponder:
        usr.autoresponderActive === undefined
          ? undefined
          : toDbBoolStr(usr.autoresponderActive),
      autoresponder_body: usr.autoresponderBody,
      status: usr.status,
      user_alias: usr.aliases?.map(({ value }) => value),
      is_testing_user: toDbBoolNum(usr.isTestingUser ?? false),
      is_testing_home_user: toDbBoolNum(usr.isTestingHomeUser ?? false),
      is_local_account: toDbBoolNum(usr.isLocalAccount ?? false),
      main_role_id: Number(usr.mainRoleId),
      domain_id: usr.domainId?.value,
    };
  },
};

const toInvitationModeApi = (
  emailInvitation?: boolean,
  smsInvitation?: boolean
): NotificationMode => {
  if (emailInvitation) {
    if (smsInvitation) return 'both';
    else return 'mail';
  } else {
    if (smsInvitation) return 'sms';
    else return 'none';
  }
};
