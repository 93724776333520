import { useCallback } from 'react';

import ColorHash from 'color-hash';

const colorHash = new ColorHash({
  lightness: [0.3, 0.4, 0.5, 0.6, 0.7],
  saturation: [0.2, 0.3, 0.4, 0.5],
  hue: { min: 165, max: 329 },
});

const colorHashCache: { [value: string]: string } = {};

export default function useColorHash(useCache?: boolean) {
  const hexColorHash = useCallback(
    (value: string) => {
      if (useCache) {
        if (colorHashCache[value]) {
          return colorHashCache[value];
        } else {
          const color = colorHash.hex(value);
          colorHashCache[value] = color;
          return color;
        }
      } else {
        return colorHash.hex(value);
      }
    },
    [useCache]
  );

  return { hexColorHash };
}
