import ApiResourceContract from '../apiResourceContract';

export interface PermissionApi {
  id: number;
  name: string;
  group: string | null;
  description?: string | null;
}

export interface GenericPermission {
  entity: string;
  action: string;
  range: PermissionRange;
  description?: string;
}

export interface Permission extends GenericPermission {
  id: string;
  group: string;
}

type UpdatePermission = Permission;

type UpdatePermissionApi = PermissionApi;

type CreatePermission = Permission;

type CreatePermissionApi = PermissionApi;

export const fromPermissionApi: (
  permApi: string
) => GenericPermission | undefined = permApi => {
  const permParts = permApi.split('.');

  if (permParts.length === 3) {
    return {
      entity: permParts[0],
      action: permParts[1],
      range: permParts[2] as PermissionRange,
    };
  } else if (permParts.length === 2) {
    return {
      entity: permParts[0],
      action: permParts[1],
      range: 'yes',
    };
  } else {
    return undefined;
  }
};

export const toPermissionApi: (perm: {
  entity: string;
  action: string;
  range: PermissionRange;
}) => string = ({ action, entity, range }) => {
  if (range === 'yes') return [entity, action].join('.');
  return [entity, action, range].join('.');
};
export const hierarchyToPermissionsApi: (
  permissions: PermissionHierarchy
) => string[] = permissions =>
  Object.keys(permissions).flatMap(group =>
    Object.keys(permissions[group]).flatMap(entity =>
      Object.keys(permissions[group][entity])
        .filter(action => !!permissions[group][entity][action])
        .map(action =>
          toPermissionApi({
            entity,
            action,
            range: permissions[group][entity][action]!,
          })
        )
    )
  );

export const parsePermissionsHierarchyAvailRanges: (
  perms: Permission[]
) => PermissionHierarchyAvailRanges = perms => {
  const permissions: PermissionHierarchyAvailRanges = {};

  perms.forEach(perm => {
    const { entity, action, range, group } = perm;

    permissions[group] ??= {};
    permissions[group][entity] ??= {};
    permissions[group][entity][action] ??= [];
    permissions[group][entity][action].push(range);
  });

  return permissions;
};

export const parsePermissionHierarchy: (
  perms: Permission[],
  permissionToGroup?: PermissionGroupHierarchy<string>
) => PermissionHierarchy = (perms, permissionToGroup) => {
  const permissions: PermissionHierarchy = {};
  perms.forEach(perm => {
    const { entity, action, range } = perm;
    let { group } = perm;

    if (permissionToGroup) group ??= permissionToGroup[entity][action];

    permissions[group] ??= {};
    permissions[group][entity] ??= {};
    permissions[group][entity][action] = range;
  });
  return permissions;
};

const permissionRangePower: Record<PermissionRange, number> = {
  own: 1,
  tenant: 3,
  yes: 3,
};

export const isPermissionRangeLower = (
  rangeA: PermissionRange | undefined,
  rangeB: PermissionRange | undefined
): boolean => {
  if (rangeA === undefined && rangeB === undefined) return true;
  else if (rangeA === undefined) return false;
  else if (rangeB === undefined) return true;
  return permissionRangePower[rangeA] < permissionRangePower[rangeB];
};

export const pickWiderPermissionRange = (
  rangeA: PermissionRange | undefined,
  rangeB: PermissionRange | undefined
): PermissionRange | undefined => {
  if (rangeA === undefined && rangeB === undefined) return undefined;
  else if (rangeA === undefined) return rangeB;
  else if (rangeB === undefined) return rangeA;
  return permissionRangePower[rangeA] > permissionRangePower[rangeB]
    ? rangeA
    : rangeB;
};

enum PermissionRanges {
  own = 'own',
  tenant = 'tenant',
  yes = 'yes',
}

export type PermissionRange = keyof typeof PermissionRanges;

export type UiPermissionRange = keyof typeof PermissionRanges | 'none';

export type PermissionGroupHierarchy<T = PermissionRange | undefined> = {
  [entity: string]: { [action: string]: T };
};

export type PermissionHierarchy = {
  [group: string]: PermissionGroupHierarchy;
};

export type PermissionHierarchyAvailRanges = {
  [group: string]: {
    [entity: string]: { [action: string]: PermissionRange[] };
  };
};

export const permissionApiResourceContract: ApiResourceContract<
  PermissionApi,
  Permission,
  CreatePermissionApi,
  CreatePermission,
  UpdatePermissionApi,
  UpdatePermission,
  { id: string }
> = {
  singleEndpoint: ({ id }) => `nothing/${id}`,
  createEndpoint: () => 'nothing/',
  updateEndpoint: ({ id }) => `nothing/${id}`,
  listEndpoint: () => 'permissions/',
  deleteSingleEndpoint: ({ id }) => `nothing/${id}`,
  name: 'permission',
  apiConverter: permApi => {
    const perm = fromPermissionApi(`${permApi.name}`);

    if (perm)
      return {
        id: permApi.id.toString(),
        group: permApi.group === '' ? '-' : permApi.group ?? 'general',
        description: permApi.description ?? undefined,
        ...perm,
      };
    else return null;
  },
  feFieldToApiField: {
    id: 'id',
  },
};
