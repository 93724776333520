import { MouseEvent, useState } from 'react';

import {
  Launch as LaunchIcon,
  Logout as LogoutIcon,
  MenuBook as MenuBookIcon,
  MoreVert as MoreIcon,
} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { useAuth } from '../contexts/AuthCtx';
import { ALLOW_THEME_CHANGE, VERSION } from '../env';
import useApiVersion from '../hooks/useApiVersion';
import useIsMobile from '../hooks/useIsMobile';
import useSafeTranslation from '../hooks/useSafeTranslation';
import Avatar from './Avatar';
import ColorModeToggle from './ColorModeToggle';
import LanguageSelector from './LanguageSelector';
import LoginAsSelector from './LoginAsSelector';

function TopBarMenu() {
  const { user, originalUser } = useAuth();
  const isMobile = useIsMobile();
  const { T } = useSafeTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logout } = useAuth();

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const apiVersion = useApiVersion();

  return (
    <>
      <IconButton size='large' onClick={handleMenu} color='inherit'>
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isMobile && user && (
          <MenuItem
            onClick={handleClose}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <Avatar name={originalUser?.name} imgSrc={originalUser?.picSrc} />
            <Typography>
              <b>{originalUser?.name}</b>
            </Typography>
          </MenuItem>
        )}
        {isMobile && user && (
          <MenuItem onClick={handleClose}>
            <LoginAsSelector />
          </MenuItem>
        )}
        <MenuItem onClick={handleClose}>
          <LanguageSelector sx={{ width: '100%' }} />
        </MenuItem>
        {ALLOW_THEME_CHANGE && (
          <MenuItem onClick={handleClose}>
            <ColorModeToggle />
          </MenuItem>
        )}
        <a
          target='_blank'
          href='https://api.futureinvest.io/v10/wiki'
          rel='noopener noreferrer'
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <MenuItem
            onClick={handleClose}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ListItemIcon>
                <MenuBookIcon fontSize='small' />
              </ListItemIcon>
              {T('handbook')}
            </Box>
            <Box>
              <IconButton disableRipple sx={{ p: 0, mr: 1 }}>
                <LaunchIcon fontSize='small' />
              </IconButton>
            </Box>
          </MenuItem>
        </a>
        <MenuItem disableRipple sx={{ pointerEvents: 'none' }}>
          <ListItemIcon>
            <InfoIcon fontSize='small' />
          </ListItemIcon>
          <Box
            display='grid'
            justifyContent='space-between'
            gridTemplateColumns='1fr 2fr'
            columnGap={0.5}
          >
            <Typography variant='caption'>WEB APP:</Typography>
            <Typography variant='caption'>v{VERSION}</Typography>
            <Typography variant='caption'>API:</Typography>
            <Typography variant='caption'>v{apiVersion}</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize='small' />
          </ListItemIcon>
          {T('logout')}
        </MenuItem>
      </Menu>
    </>
  );
}

export default TopBarMenu;
