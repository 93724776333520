import { apiGet, apiPost } from '../api';
import { User, UserApi, userApiResourceContract } from './userResource';

interface AuthDataApi extends UserApi {
  token: string;
}

interface AuthData extends User {
  token: string;
}

const authApiConverter = async (user: AuthDataApi): Promise<AuthData> => {
  const convertedUser = await userApiResourceContract.apiConverter(user)!;

  return {
    ...convertedUser,
    token: user.token,
  };
};

export const loginApiCall = (email: string, password: string, token?: string) =>
  apiPost<AuthDataApi>({
    endpoint: 'user/login',
    body: {
      email,
      password,
      channel: 'web',
      subChannel: 'redux',
    },
    token,
  }).then(data => authApiConverter(data));

export const getSelfUserUserApi = (token: string) =>
  apiGet<AuthDataApi, AuthData>(
    {
      endpoint: 'user/me',
      token,
    },
    authApiConverter
  );
