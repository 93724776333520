import i18n from 'i18next';
import lngDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import toast from 'react-hot-toast';
import { initReactI18next } from 'react-i18next';

import { IS_DEV_ENV } from './env';

export type Language = 'it' | 'en';

i18n
  .use(lngDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init(
    {
      debug: IS_DEV_ENV,
      fallbackLng: 'it',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      returnEmptyString: true,
      react: {},
      returnObjects: true,
      ns: ['translation'],
      missingKeyHandler: IS_DEV_ENV
        ? (languages, ns, key) => {
            if (window.location.pathname !== '/organogram')
              toast(
                t => (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => toast.dismiss(t.id)}
                  >
                    Missing translation for languages {languages.join('')} in ns{' '}
                    {ns} for key {key}
                  </span>
                ),
                {
                  id: [languages, ns, key].join(),
                  duration: 3000,
                  style: { color: '#d95f02' },
                }
              );
          }
        : undefined,
    },
    err => {
      if (err) {
        console.error('ERROR WHEN LOADING TRANSLATIONS', err);
      }
    }
  );
export default i18n;
