import { Toaster } from 'react-hot-toast';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function AppToaster() {
  const theme = useTheme();
  return (
    <Toaster
      position='bottom-left'
      gutter={15}
      toastOptions={{
        style: {
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[6],
        },
        iconTheme: {
          primary: theme.palette.primary.main,
          secondary: theme.palette.secondary.main,
        },
        success: {
          icon: <CheckCircleOutlineIcon />,
          style: {
            color: theme.palette.success.main,
            backgroundColor: theme.palette.success.contrastText,
          },
          duration: 4000,
        },
        loading: {
          icon: <CircularProgress size='1.4rem' />,
          style: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.contrastText,
          },
        },
        error: {
          icon: <ErrorOutlineIcon />,
          style: {
            color: theme.palette.error.main,
            backgroundColor: theme.palette.error.contrastText,
          },
          duration: 6000,
        },
      }}
    />
  );
}

export default AppToaster;
