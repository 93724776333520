import ApiResourceContract from '../apiResourceContract';

interface AliasApi {
  _id: string;
  name: string;
  user_tokens: {
    token: string;
  }[];
}

export interface Alias {
  id: string;
  name: string;
  token: string | undefined;
}

type CreateAlias = Alias;

type CreateAliasApi = AliasApi;

export const aliasApiResourceContract: ApiResourceContract<
  AliasApi,
  Alias,
  CreateAliasApi,
  CreateAlias,
  CreateAliasApi,
  CreateAlias & { id: string },
  { id: string }
> = {
  singleEndpoint: ({ id }) => `user/alias/${id}`,
  listEndpoint: () => 'user/alias/list/',
  createEndpoint: () => 'user/alias/',
  updateEndpoint: () => 'user/alias/',
  deleteSingleEndpoint: ({ id }) => `user/alias/${id}`,
  name: 'alias',
  apiConverter: alias => ({
    id: alias._id,
    name: alias.name,
    token:
      alias.user_tokens.length > 0 ? alias.user_tokens[0].token : undefined,
  }),
};
