import AppToaster from './Toaster';
import ApiHostName from './components/ApiHostName';
import NetworkOfflineToast from './components/NetworkOfflineToast';
import ActiveTabsCtxProvider from './contexts/ActiveTabsCtx';
import PageTitleSuffixCtxProvider from './contexts/PageTitleSuffixCtx';
import AppThemeProvider from './externalProviders/AppThemeProvider';
import DateLocaleProvider from './externalProviders/DateLocaleProvider';
import RHFDevToolsProvider from './externalProviders/RHFDevToolsProvider';
import ReactQueryClientProvider from './externalProviders/ReactQueryProvider';
import useLocalizedZodError from './hooks/useLocalizedZodError';
import { PopupFormCtxProvider } from './hooks/useOpenForm';
import AppRouter from './routes';

function App() {
  useLocalizedZodError();

  return (
    <ActiveTabsCtxProvider>
      <AppThemeProvider>
        <ReactQueryClientProvider>
          <DateLocaleProvider>
            <PageTitleSuffixCtxProvider>
              <NetworkOfflineToast />
              <PopupFormCtxProvider>
                <RHFDevToolsProvider>
                  <AppRouter />
                </RHFDevToolsProvider>
              </PopupFormCtxProvider>
            </PageTitleSuffixCtxProvider>
            <AppToaster />
          </DateLocaleProvider>
        </ReactQueryClientProvider>
        <ApiHostName />
      </AppThemeProvider>
    </ActiveTabsCtxProvider>
  );
}

export default App;
