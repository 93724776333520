import { PropsWithChildren, createContext, useContext } from 'react';

import en from 'date-fns/locale/en-US';
import it from 'date-fns/locale/it';
import 'i18next';
import { useTranslation } from 'react-i18next';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Language } from '../i18n';

const langToDateLocale: { [lng in Language]: Locale } = {
  it,
  en,
};

const LocaleCtx = createContext<Locale>(langToDateLocale['it']);

function DateLocaleProvider({ children }: PropsWithChildren<{}>) {
  const {
    i18n: { language },
  } = useTranslation();
  const currentLocale = langToDateLocale[language.slice(0, 2) as Language];
  return (
    <LocaleCtx.Provider value={currentLocale}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={currentLocale}
      >
        {children}
      </LocalizationProvider>
    </LocaleCtx.Provider>
  );
}

export const useDateLocale = () => useContext(LocaleCtx);

export default DateLocaleProvider;
