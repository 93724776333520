import { useCallback, useEffect, useState } from 'react';

import { StringMap, TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

import i18n from '../i18n';

// TODO think if there is the need to add support for male and female
type TFunctionSafe = (
  toTranslate: string,
  count?: number,
  options?: TOptions<StringMap>
) => string;

export type withTSafe<RetType, Options = undefined> = Options extends undefined
  ? (t: TFunctionSafe) => RetType
  : (t: TFunctionSafe, opt: Options) => RetType;

type TranslationNs = 'settings' | 'authorization';

function useSafeTranslation(ns?: TranslationNs) {
  const { t } = useTranslation();

  const T: TFunctionSafe = useCallback(
    (toTranslate, count = 1, options) => {
      const val = t(toTranslate, { count, ns, ...options }) as
        | string
        | { _: string };

      return val ? (typeof val === 'object' ? val._ : val) : toTranslate;
    },
    [t, ns]
  );

  const [nsLoaded, setNsLoaded] = useState(
    ns ? i18n.hasResourceBundle(i18n.language, ns) : undefined
  );

  useEffect(() => {
    if (ns && !nsLoaded) {
      i18n
        .loadNamespaces([ns])
        .then(() => {
          setNsLoaded(true);
        })
        .catch(err => {
          console.error(`ERROR WHEN LOADING TRANSLATION NS ${ns}`, err);
        });
    }
  }, [ns, nsLoaded]);

  return { T, nsLoaded };
}

export default useSafeTranslation;
