import React, { Suspense } from 'react';

import { createRoot } from 'react-dom/client';

import { LicenseInfo } from '@mui/x-license-pro';

import App from './App';
import {
  LOCAL_KEY_AUTH_SSO_ID_TOKEN,
  LOCAL_KEY_AUTH_TOKEN,
  userManager,
} from './contexts/AuthCtx';
import { AUTH_URL, MUI_PRO_LICENSE_KEY } from './env';
import './global.css';
import './i18n';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('app');

LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY);

if (container) {
  const root = createRoot(container);
  if (!AUTH_URL) {
    // Skip sso
    root.render(
      <React.StrictMode>
        <Suspense fallback=''>
          <App />
        </Suspense>
      </React.StrictMode>
    );
  } else if (userManager) {
    const token = localStorage.getItem(LOCAL_KEY_AUTH_TOKEN);
    if (token && token !== 'null') {
      userManager.getUser().then(user => {
        if (!user || user.expired) {
          localStorage.removeItem(LOCAL_KEY_AUTH_TOKEN);
          localStorage.removeItem(LOCAL_KEY_AUTH_SSO_ID_TOKEN);
          userManager!.signinRedirect();
        } else {
          root.render(
            <React.StrictMode>
              <Suspense fallback=''>
                <App />
              </Suspense>
            </React.StrictMode>
          );
        }
      });
    } else {
      userManager!
        .signinRedirectCallback()
        .then(async user => {
          await userManager!.storeUser(user);
          localStorage.setItem(LOCAL_KEY_AUTH_TOKEN, user.access_token);
          if (user.id_token) {
            localStorage.setItem(LOCAL_KEY_AUTH_SSO_ID_TOKEN, user.id_token);
          }

          window.location.replace(window.location.origin);
        })
        .catch(() => {
          userManager!.signinRedirect();
        });
    }
  }
} else throw new Error('App container (div #app) not found');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
