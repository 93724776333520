import { Box } from '@mui/material';

import { API_URL_HOSTNAME, IS_DEV_ENV } from '../env';

function ApiHostName() {
  return IS_DEV_ENV ? (
    <Box
      position='fixed'
      width='100%'
      display='flex'
      justifyContent='center'
      bottom={12}
      sx={{
        pointerEvents: 'none',
      }}
      zIndex={99999}
    >
      <Box
        bgcolor='#c52c3b'
        color='#FFFFFF'
        borderRadius={2}
        py={0.5}
        px={1}
        boxShadow={5}
        sx={{ pointerEvents: 'all' }}
      >
        {API_URL_HOSTNAME}
      </Box>
    </Box>
  ) : null;
}

export default ApiHostName;
