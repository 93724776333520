import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useId } from '@mantine/hooks';

import { CreateUpdateFormMode } from '../components/CreateUpdateResourceFormWrapper';
import { deleteUndefinedProperties } from '../utils';

type TPopupFormId = Set<string>;

type FormData = {
  [formId: string]: {
    initialValues: any;
    mode: CreateUpdateFormMode | undefined;
    title: string | undefined;
    hiddenFields: string[] | undefined;
  };
};

type OpenFormArgs<FormInputs = any> = {
  mode: CreateUpdateFormMode;
  title?: string;
  initialValues: Partial<FormInputs> | null;
  hiddenFields?: (keyof FormInputs)[];
};

type TTogglePopupFormCtx<FormInputs = any> = {
  openForm: (formId: string, args: OpenFormArgs<FormInputs>) => void;
  closeForm: (formId: string) => void;
};

type TPopupFormCtx = {
  openFormIds: TPopupFormId;
  formData: FormData;
} & TTogglePopupFormCtx;

const TogglePopupFormCtx = createContext<TTogglePopupFormCtx>({
  openForm: () => {},
  closeForm: () => {},
});

const PopupFormCtx = createContext<TPopupFormCtx>({
  openFormIds: new Set(),
  formData: {},
  openForm: () => {},
  closeForm: () => {},
});

export const PopupFormCtxProvider = ({ children }: PropsWithChildren<{}>) => {
  const [openFormIds, setOpenFormIds] = useState<TPopupFormId>(new Set());
  const [formData, setFormData] = useState<FormData>({});

  const openForm = useCallback(
    (
      formId: string,
      { mode, title, initialValues, hiddenFields }: OpenFormArgs
    ) => {
      setOpenFormIds(prev => {
        prev.add(formId);
        return prev;
      });
      setFormData(prev => ({
        ...prev,
        [formId]: {
          initialValues: deleteUndefinedProperties(initialValues),
          hiddenFields: hiddenFields as string[],
          mode,
          title,
        },
      }));
    },
    [setFormData, setOpenFormIds]
  );

  const closeForm = useCallback(
    (formId: string) => {
      setOpenFormIds(prev => {
        prev.delete(formId);
        return prev;
      });
      setFormData(prev => {
        const newFormData = { ...prev };
        delete newFormData[formId];
        return newFormData;
      });
    },
    [setOpenFormIds, setFormData]
  );

  const toggleCtxValue = useMemo(
    () => ({
      openForm,
      closeForm,
    }),
    [openForm, closeForm]
  );

  return (
    <TogglePopupFormCtx.Provider value={toggleCtxValue}>
      <PopupFormCtx.Provider
        value={{
          openFormIds,
          formData,
          openForm,
          closeForm,
        }}
      >
        {children}
      </PopupFormCtx.Provider>
    </TogglePopupFormCtx.Provider>
  );
};

// TODO the current implementation could be a bit heavy on the rendering because many components rerender when a form is open, should investigate if the problem is related to the implementation or its usage in the app
export function useToggleForm<FormInputs>() {
  const formId = useId();
  const { openForm, closeForm } = useContext(TogglePopupFormCtx);

  return {
    formId,
    openForm: (args: OpenFormArgs<FormInputs>) => openForm(formId, args),
    closeForm: () => closeForm(formId),
  };
}

export function useOpenForm<FormInputs>(formId: string) {
  const { openFormIds, formData, closeForm } = useContext(PopupFormCtx);
  const { initialValues, hiddenFields, mode, title } = formData[formId] ?? {};
  return useMemo(
    () => ({
      open: openFormIds.has(formId),
      initialValues: (initialValues as Partial<FormInputs>) ?? undefined,
      mode,
      title,
      hiddenFields: hiddenFields as (keyof FormInputs)[] | undefined,
      closeForm: () => closeForm(formId),
    }),
    [formId, hiddenFields, initialValues, mode, openFormIds, title, closeForm]
  );
}
