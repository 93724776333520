import { PropsWithChildren, createContext, useEffect, useState } from 'react';

import { randomTimeId } from '../utils';

const ACTIVE_TABS_LOCAL_STORAGE_KEY = 'active-tabs';

export const ActiveTabsCtx = createContext<number | undefined>(undefined);

const countActiveTabs = (storage: Storage | null) =>
  Object.keys(storage ?? {}).filter(key =>
    key.startsWith(ACTIVE_TABS_LOCAL_STORAGE_KEY)
  ).length;

const ActiveTabsCtxProvider = ({ children }: PropsWithChildren<{}>) => {
  const [activeTabs, setActiveTabs] = useState<number>(
    countActiveTabs(localStorage)
  );
  useEffect(() => {
    const tabId = randomTimeId(ACTIVE_TABS_LOCAL_STORAGE_KEY);
    const onStorageChange = (ev: StorageEvent) => {
      setActiveTabs(countActiveTabs(ev.storageArea));
    };

    window.addEventListener('storage', onStorageChange);

    const onWindowUnload = () => {
      localStorage.removeItem(tabId);
    };

    localStorage.setItem(tabId, '');
    setActiveTabs(countActiveTabs(localStorage));

    window.addEventListener('unload', onWindowUnload);
    return () => {
      localStorage.removeItem(tabId);
      window.removeEventListener('storage', onStorageChange);
      window.removeEventListener('unload', onWindowUnload);
    };
  }, []);

  return (
    <ActiveTabsCtx.Provider value={activeTabs}>
      {children}
    </ActiveTabsCtx.Provider>
  );
};

export default ActiveTabsCtxProvider;
