import { PropsWithChildren, useCallback } from 'react';

import { useLocalStorage } from '@mantine/hooks';

import { Box, Theme, useMediaQuery } from '@mui/material';

import { APP_TITLE } from '../env';
import useIsMobile from '../hooks/useIsMobile';
import NavBar, { NAVBAR_WIDTH_CLOSED, NAVBAR_WIDTH_OPEN } from './NavBar';
import TopBar from './TopBar';

const LOCAL_KEY_EXPANDED = `${APP_TITLE}_nav_bar_expanded`;

export const TOPBAR_HEIGHT_PX = 55;
export const TOPBAR_HEIGHT = `${TOPBAR_HEIGHT_PX}px`;

export default function PrivatePageContainer(props: PropsWithChildren<{}>) {
  const { children } = props;
  const isMobile = useIsMobile();

  const [expandedNavBar, setExpandedNavBar] = useLocalStorage({
    key: LOCAL_KEY_EXPANDED,
    defaultValue: !isMobile,
    getInitialValueInEffect: false,
  });

  const onNavBarExpandedIconClick = useCallback(
    () => setExpandedNavBar(!expandedNavBar),
    [expandedNavBar, setExpandedNavBar]
  );

  const isMdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const actualExpanded = isMdScreen ? false : expandedNavBar;

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <TopBar />
      <Box display='flex' pt={TOPBAR_HEIGHT} height='100%'>
        {!isMobile && (
          <NavBar
            expanded={actualExpanded}
            onExpandIconClick={onNavBarExpandedIconClick}
            hideExpandedToggle={isMdScreen}
          />
        )}
        <Box
          display='flex'
          pl={
            isMobile
              ? 0
              : `${actualExpanded ? NAVBAR_WIDTH_OPEN : NAVBAR_WIDTH_CLOSED}px`
          }
          width='100%'
          height='100%'
          sx={{
            transition: '0.4s',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
