import { Link, useLocation } from '@tanstack/react-location';

import {
  ArrowBack as ArrowBackIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import InfoPageInBaseLayout from '../../components/layouts/InfoPageInBaseLayout';
import useSafeTranslation from '../../hooks/useSafeTranslation';

interface ErrorPageProps {
  errorType?: 'permissions' | 'invalid-route';
}

const ErrorPage = (props: ErrorPageProps) => {
  const { T } = useSafeTranslation();
  const { history } = useLocation();
  const { errorType = 'invalid-route' } = props;

  return (
    <InfoPageInBaseLayout>
      <Box my={2}>
        <Typography align='center' variant='h6'>
          {errorType === 'invalid-route'
            ? T('invalid route message')
            : T('non-authorized-msg')}
          <br />
          {window.location.pathname}
        </Typography>
      </Box>
      <Button
        sx={{ my: 2 }}
        variant='outlined'
        color='secondary'
        onClick={() => history.back()}
        startIcon={<ArrowBackIcon />}
      >
        {T('go back')}
      </Button>
      <Link to='/'>
        <Button
          sx={{ my: 2 }}
          variant='outlined'
          color='secondary'
          startIcon={<HomeIcon />}
        >
          {T('go to home page')}
        </Button>
      </Link>
    </InfoPageInBaseLayout>
  );
};

export default ErrorPage;
