import { ReactNode } from 'react';

import {
  MakeGenerics,
  Outlet,
  ReactLocation,
  Route,
  Router,
  Search,
  SyncOrAsyncElement,
  parseSearchWith,
  stringifySearchWith,
} from '@tanstack/react-location';
import { ReactLocationDevtools } from '@tanstack/react-location-devtools';
import JSURL from 'jsurl2';

import {
  AttachFile as AttachFileIcon,
  Badge as BadgeIcon,
  BuildRounded as BuildRoundedIcon,
  Campaign as CampaignIcon,
  Chat as ChatIcon,
  DashboardCustomize as DashboardIcon,
  Download,
  Email as EmailIcon,
  FactCheck as FactCheckIcon,
  EditRoad as FlowEditorIcon,
  Folder as FolderIcon,
  GroupAdd as GroupAddIcon,
  People as PeopleIcon,
  Settings as SettingsIcon,
  Support as SupportIcon,
  Style as TagsIcon,
  TravelExplore as TravelExploreIcon,
  AccountTree as TreeIcon,
  ViewColumn,
  Web,
} from '@mui/icons-material';

import PrivateOutlet from './PrivateOutlet';
import { TableParams } from './components/ApiTable/ApiTable';
import { AvailPermission } from './components/utils/PermissionsGuard';
import AuthCtxProvider from './contexts/AuthCtx';
import { IS_DEV_ENV } from './env';
import useIsMobile from './hooks/useIsMobile';
import useSafeTranslation, { withTSafe } from './hooks/useSafeTranslation';

export interface CustomRoute {
  path: string;
  icon?: ReactNode;
  name?: string;
  element?: SyncOrAsyncElement;
  requiredPermissions?: AvailPermission[];
}

const TAGS_ROUTE_PATH = '/tags';
export const CHATS_ROUTE_PATH = '/chats';
const FLOWS_ROUTE_PATH = '/flows';
export const FLOW_EDITOR_ROUTE_PATH = '/flowEditor';
export const CHANNELS_ROUTE_PATH = '/channels';
export const GROUPS_ROUTE_PATH = '/groups';
const BROADCASTS_ROUTE_PATH = '/broadcasts';
const DOCUMENTS_ROUTE_PATH = '/documents';
export const SIGNS_EDITOR_ROUTE_PATH = '/signaturesEditor';
const POOLS_ROUTE_PATH = '/pools';
export const USERS_ROUTE_PATH = '/users';
export const LOGIN_ROUTE_PATH = '/login';
export const TICKETS_ROUTE_PATH = '/tickets';
export const DASHBOARD_MOBILE_ROUTE_PATH = '/mobileDashboard';

export const NAV_BAR_ROUTES: withTSafe<CustomRoute[]> = T => [
  {
    name: T('user', 2),
    path: USERS_ROUTE_PATH,
    icon: <PeopleIcon />,
    requiredPermissions: ['user.ui'],
    element: () =>
      import('./pages/UsersPage').then(module => <module.default />),
  },
  {
    name: T('tag', 2),
    path: TAGS_ROUTE_PATH,
    requiredPermissions: ['tags.use.own'],
    icon: <TagsIcon />,
    element: () =>
      import('./pages/TagsPage').then(module => <module.default />),
  },
  {
    name: T('organogram'),
    path: 'organogram',
    requiredPermissions: ['hierarchy.ui'],
    element: () =>
      import('./pages/HierarchyExplorer/HierarchyPage').then(module => (
        <module.default />
      )),
    icon: <TreeIcon />,
  },
  {
    name: T('chat', 2),
    path: CHATS_ROUTE_PATH,
    icon: <ChatIcon />,
    requiredPermissions: ['chat.list.own'],
    element: () =>
      import('./pages/ChatsPage').then(module => (
        <module.default type='chat' />
      )),
  },
  {
    name: T('channel', 2),
    path: CHANNELS_ROUTE_PATH,
    icon: <CampaignIcon />,
    requiredPermissions: ['chat.list.own'],
    element: () =>
      import('./pages/ChatsPage').then(module => (
        <module.default type='channel' />
      )),
  },
  {
    name: T('group', 2),
    path: GROUPS_ROUTE_PATH,
    icon: <PeopleIcon />,
    requiredPermissions: ['chat.list.own'],
    element: () =>
      import('./pages/ChatsPage').then(module => (
        <module.default type='group' />
      )),
  },
  {
    name: T('broadcast', 2),
    path: BROADCASTS_ROUTE_PATH,
    icon: <EmailIcon />,
    requiredPermissions: ['broadcast.list.own'],
    element: () =>
      import('./pages/BroadcastsPage').then(module => (
        <module.default multiTenant={false} />
      )),
  },
  {
    name: T('document', 2),
    path: DOCUMENTS_ROUTE_PATH,
    icon: <AttachFileIcon />,
    element: () =>
      import('./pages/DocsPage').then(module => <module.default />),
    requiredPermissions: ['document.list.own'],
  },
  {
    name: T('pool', 2),
    path: POOLS_ROUTE_PATH,
    icon: <FolderIcon />,
    element: () =>
      import('./pages/PoolsPage').then(module => <module.default />),
    requiredPermissions: ['document_pool.list.own'],
  },
  {
    name: T('flow', 2),
    path: FLOWS_ROUTE_PATH,
    icon: <FactCheckIcon />,
    requiredPermissions: ['flow.list.own'],
    element: () =>
      import('./pages/FlowsPage').then(module => <module.default />),
  },
  {
    name: T('adoption', 2),
    path: '/adoptions',
    requiredPermissions: ['adoption.ui'],
    icon: <SupportIcon />,
    element: () =>
      import('./pages/AdoptionsPage').then(module => <module.default />),
  },
  {
    name: T('mobile dashboard'),
    path: DASHBOARD_MOBILE_ROUTE_PATH,
    icon: <DashboardIcon />,
    requiredPermissions: ['dashboard.ui'],
    element: () =>
      import('./pages/MobileDashboard/MobileDashboard').then(module => (
        <module.default />
      )),
  },
  {
    name: T('authorization', 2),
    path: '/authorization',
    requiredPermissions: ['authorization.ui'],
    icon: <BadgeIcon />,
    element: () =>
      import('./pages/AuthorizationMngPage/AuthorizationMngPage').then(
        module => <module.default />
      ),
  },
  {
    name: T('onboarding'),
    path: '/onboarding',
    icon: <GroupAddIcon />,
    requiredPermissions: ['authorization.ui'],
    element: () =>
      import('./pages/OnboardingPage').then(module => <module.default />),
  },
  {
    name: T('tenant settings'),
    path: '/settings',
    icon: <SettingsIcon />,
    requiredPermissions: ['settings.ui'],
    element: () =>
      import('./pages/SettingsPage/SettingsPage').then(module => (
        <module.default />
      )),
  },
  {
    name: T('internal tools'),
    path: '/internalTools',
    icon: <BuildRoundedIcon />,
    requiredPermissions: ['internal-tools.ui'],
    element: () =>
      import('./pages/InternalToolsPage/InternalToolsPage').then(module => (
        <module.default />
      )),
  },
  {
    name: T('tenant monitor'),
    path: '/multi-broadcasts',
    icon: <TravelExploreIcon />,
    requiredPermissions: ['monitor.ui'],
    element: () =>
      import('./pages/BroadcastsPage').then(module => (
        <module.default multiTenant />
      )),
  },
  {
    name: T('template', 2),
    path: '/templates',
    icon: <ViewColumn />,
    requiredPermissions: ['template.ui'],
    element: () =>
      import('./pages/TemplatesPage').then(module => <module.default />),
  },
  {
    name: T('export file', 2),
    path: '/exports',
    icon: <Download />,
    requiredPermissions: ['export.ui'],
    element: () =>
      import('./pages/ExportsPage').then(module => <module.default />),
  },
  {
    name: T('metadata type', 2),
    path: '/metadataTypes',
    icon: <BuildRoundedIcon />,
    requiredPermissions: ['user.metadatatype'],
    element: () =>
      import('./pages/MetadataTypesPage').then(module => <module.default />),
  },
  {
    name: T('domain', 2),
    path: '/domains',
    icon: <Web />,
    requiredPermissions: ['domain.ui'],
    element: () =>
      import('./pages/DomainsPage').then(module => <module.default />),
  },
  {
    name: T('ticket type', 2),
    path: '/ticketTypes',
    icon: <BuildRoundedIcon />,
    requiredPermissions: ['ticket.ui'],
    element: () =>
      import('./pages/TicketTypesPage').then(module => <module.default />),
  },
  {
    name: T('ticket', 2),
    path: TICKETS_ROUTE_PATH,
    icon: <BuildRoundedIcon />,
    requiredPermissions: ['ticket.ui'],
    element: () =>
      import('./pages/TicketsPage').then(module => <module.default />),
  },
  {
    name: T('badge', 2),
    path: '/badges',
    icon: <BuildRoundedIcon />,
    requiredPermissions: ['badge.ui'],
    element: () =>
      import('./pages/BadgesPage').then(module => <module.default />),
  },
];

const PROTECTED_ROUTES: withTSafe<CustomRoute[]> = T => [
  {
    path: `${TAGS_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/inspectorPages/TagInspector').then(module => (
        <module.default />
      )),
  },
  {
    path: `${USERS_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/inspectorPages/UserInspector').then(module => (
        <module.default />
      )),
  },
  {
    path: `${CHATS_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/inspectorPages/ChatInspector').then(module => (
        <module.default />
      )),
    requiredPermissions: ['chat.list.own'],
  },
  {
    path: `${GROUPS_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/inspectorPages/ChatInspector').then(module => (
        <module.default />
      )),
    requiredPermissions: ['chat.list.own'],
  },
  {
    path: `${CHANNELS_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/inspectorPages/ChatInspector').then(module => (
        <module.default />
      )),
    requiredPermissions: ['chat.list.own'],
  },
  {
    path: `${BROADCASTS_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/inspectorPages/BroadcastInspector').then(module => (
        <module.default />
      )),
    requiredPermissions: ['broadcast.list.own'],
  },
  {
    path: `${DOCUMENTS_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/inspectorPages/DocInspector').then(module => (
        <module.default />
      )),
  },
  {
    path: `${SIGNS_EDITOR_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/SignaturesEditorPage').then(module => <module.default />),
  },
  {
    path: `${POOLS_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/inspectorPages/PoolInspector').then(module => (
        <module.default />
      )),
    requiredPermissions: ['document_pool.list.own'],
  },
  {
    path: `${FLOWS_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/inspectorPages/FlowInspector').then(module => (
        <module.default />
      )),
    requiredPermissions: ['flow.list.own'],
  },
  {
    name: 'flow editor',
    path: `${FLOW_EDITOR_ROUTE_PATH}/:flowId`,
    icon: <FlowEditorIcon />,
    requiredPermissions: ['flow.list.own'],
    element: () =>
      import('./pages/FlowEditorPage').then(module => <module.default />),
  },
  {
    path: `${TICKETS_ROUTE_PATH}/:id`,
    element: () =>
      import('./pages/inspectorPages/TicketInspector').then(module => (
        <module.default />
      )),
  },
  ...NAV_BAR_ROUTES(T),
];

const ALL_ROUTES: withTSafe<Route[]> = T => [
  {
    path: '/recoverPsw',
    element: () =>
      import('./pages/utilityPages/RecoverPswPage').then(module => (
        <module.default />
      )),
  },
  {
    path: LOGIN_ROUTE_PATH,
    element: () =>
      import('./pages/utilityPages/LoginPage').then(module => (
        <module.default />
      )),
  },
  {
    path: '/privacy',
    element: () =>
      import('./pages/utilityPages/PrivacyPage').then(module => (
        <module.default />
      )),
  },
  {
    element: <PrivateOutlet />,
    children: [
      {
        path: '/',
        element: () =>
          import('./pages/HomePage').then(module => <module.default />),
      },
      ...PROTECTED_ROUTES(T),
      {
        path: '*',
        element: () =>
          import('./pages/utilityPages/InvalidRoutePage').then(module => (
            <module.default />
          )),
      },
    ],
  },
];

export const removeTableParamsFromUrlSearch = (
  oldSearch: Partial<Search<unknown>> | undefined
) => {
  if (oldSearch) {
    const { tablesParams, ...noTableParamsSearch } = oldSearch;
    return noTableParamsSearch;
  }
  return {};
};

export const removeOrganogramParamsFromUrlSearch = (
  oldSearch: Partial<Search<unknown>> | undefined
) => {
  if (oldSearch) {
    const { openOnUserId, ...noOrganogramParamsSearch } = oldSearch;
    return noOrganogramParamsSearch;
  }
  return {};
};

const location = new ReactLocation({
  parseSearch: parseSearchWith(JSURL.parse),
  stringifySearch: stringifySearchWith(JSURL.stringify),
});

export type CustomLocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
    tablesParams?: {
      [tableId: string]: TableParams | undefined;
    };
  };
  Params: {
    id?: string;
  };
}>;

export default function AppRoutes() {
  const { T } = useSafeTranslation();

  const routes = ALL_ROUTES(T);
  const isMobile = useIsMobile();
  return (
    <Router location={location} routes={routes}>
      <AuthCtxProvider>
        <Outlet />
      </AuthCtxProvider>
      {IS_DEV_ENV && (
        <ReactLocationDevtools
          position='bottom-left'
          toggleButtonProps={{ style: isMobile ? {} : { left: 40 } }}
        />
      )}
    </Router>
  );
}
