import { memo } from 'react';

import { Link, useLocation } from '@tanstack/react-location';

import { ArrowBackIosNewRounded as ArrowIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';

import { APP_TITLE } from '../env';
import useIsMobile from '../hooks/useIsMobile';
import useSafeTranslation from '../hooks/useSafeTranslation';
import {
  CustomRoute,
  NAV_BAR_ROUTES,
  removeOrganogramParamsFromUrlSearch,
} from '../routes';
import { removeTableParamsFromUrlSearch } from '../routes';
import { TOPBAR_HEIGHT } from './PrivatePageContainer';
import TenantImage from './TenantImage';
import { useCanAccess } from './utils/PermissionsGuard';

interface LinkListProps {
  routes: CustomRoute[];
  expanded: boolean;
  onClick?: () => void;
}

const LinkList = (props: LinkListProps) => {
  const { routes, expanded, onClick } = props;
  const { current: currentLocation } = useLocation();
  const isMobile = useIsMobile();

  const canAccess = useCanAccess();

  return (
    <List
      sx={{
        overflow: 'auto',
        width: '100%',
        transition: '0.4s',
        p: 0,
      }}
    >
      {routes
        .filter(({ requiredPermissions }) => canAccess(requiredPermissions))
        .map(route => {
          const { name, icon, path } = route;

          const isActive = currentLocation.pathname.includes(path);

          return (
            <Link
              to={path}
              search={oldSearch =>
                removeTableParamsFromUrlSearch(
                  removeOrganogramParamsFromUrlSearch(oldSearch)
                )
              }
              key={path}
            >
              <Tooltip
                title={!expanded ? name ?? '' : ''}
                placement='right'
                arrow
              >
                <ListItemButton
                  key={name}
                  disableRipple={isActive}
                  onClick={onClick}
                  sx={{
                    p: expanded ? 0 : undefined,
                    px: isMobile ? 0 : expanded ? 3 : undefined,
                    color: isActive ? 'primary.main' : '',
                    justifyContent: 'center',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: expanded ? 1 : undefined,
                      color: isActive ? 'primary.main' : '',
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  {expanded && (
                    <ListItemText
                      primary={name}
                      sx={{
                        fontWeight: isActive ? 900 : '',
                      }}
                    />
                  )}
                </ListItemButton>
              </Tooltip>
            </Link>
          );
        })}
    </List>
  );
};
export const NAVBAR_WIDTH_OPEN = 250;
export const NAVBAR_WIDTH_CLOSED = 80;

type NavBarProps = {
  onExpandIconClick: () => void;
  hideExpandedToggle?: boolean;
} & (
  | {
      expanded: true;
      fullHeight: true;
    }
  | {
      expanded: boolean;
      fullHeight?: never;
    }
);

function NavBar(props: NavBarProps) {
  const { expanded, onExpandIconClick, fullHeight, hideExpandedToggle } = props;
  const { T } = useSafeTranslation();
  const isMobile = useIsMobile();

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      alignItems='center'
      px={isMobile ? 2 : 0}
      pr={isMobile ? 3 : undefined}
      zIndex={99}
      height={fullHeight ? '100%' : `calc(100% - ${TOPBAR_HEIGHT})`}
      sx={{
        borderRightStyle: 'solid',
        borderRightWidth: '2px',
        borderColor: 'divider',
        position: 'fixed',
        width: isMobile
          ? undefined
          : expanded
          ? NAVBAR_WIDTH_OPEN
          : NAVBAR_WIDTH_CLOSED,
        overflowY: 'auto',
        transition: '0.4s',
        bgcolor: 'background.default',
      }}
    >
      <Box width='100%' pt={isMobile ? undefined : 0.5}>
        {isMobile && (
          <Box
            display='flex'
            alignItems='center'
            gap={1}
            my={1}
            justifyContent='space-between'
          >
            <Typography variant='h5' color='primary' align='center'>
              {APP_TITLE}
            </Typography>
            <TenantImage style={{ maxHeight: 40 }} />
          </Box>
        )}
        <LinkList
          expanded={expanded ?? false}
          routes={NAV_BAR_ROUTES(T)}
          onClick={isMobile ? onExpandIconClick : undefined}
        />
      </Box>
      {!hideExpandedToggle && (
        <IconButton
          size='large'
          edge='start'
          color='primary'
          sx={{ mb: 0.5 }}
          onClick={onExpandIconClick}
        >
          <ArrowIcon
            sx={{
              transform: `rotateZ(${expanded ? 0 : 180}deg)`,
              transition: 'transform 0.4s',
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}

export default memo(NavBar);
