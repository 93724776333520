import { mergeAll } from 'ramda';
import { z } from 'zod';

import pkgJson from '../package.json';

const { NODE_ENV } = process.env;

declare global {
  interface Window {
    ENV: { [envVarName: string]: string };
  }
}

const envSchema = z.object({
  REACT_APP_FUTURE_API_URL: z.string().url(),
  REACT_APP_FUTURE_PRIMARY_COLOR: z.string(),
  REACT_APP_FUTURE_SECONDARY_COLOR: z.string(),
  REACT_APP_FUTURE_TITLE: z.string(),
  REACT_APP_FUTURE_MUI_PRO_LICENSE_KEY: z.string(),
  REACT_APP_FUTURE_AVAILABLE_LANGUAGES: z
    .string()
    .transform(availLangs => availLangs.split(',').map(lng => lng.trim())),
  REACT_APP_FUTURE_PRIMARY_COLOR_DARK: z.string().optional(),
  REACT_APP_FUTURE_SECONDARY_COLOR_DARK: z.string().optional(),
  REACT_APP_SANDBOX: z.string().optional(),
  REACT_APP_FUTURE_AUTH_URL: z.string().optional(),
  REACT_APP_FUTURE_AUTH_REALM: z.string().optional(),
  REACT_APP_FUTURE_AUTH_CLIENT_ID: z.string().optional(),
  REACT_APP_FUTURE_AUTH_SECRET_KEY: z.string().optional(),
});

const envParseRes = envSchema.safeParse(mergeAll([process.env, window.ENV]));

if (!envParseRes.success) {
  const { error } = envParseRes;
  const { fieldErrors } = error.flatten();
  throw new Error(
    `\n\nINVALID ENV VARIABLES:\n${Object.keys(fieldErrors)
      .map(
        field =>
          `${field}: ${fieldErrors[field as keyof typeof fieldErrors]?.join(
            '. '
          )}`
      )
      .join('\n')}\n\n`
  );
}

export const {
  REACT_APP_FUTURE_API_URL: API_URL,
  REACT_APP_FUTURE_PRIMARY_COLOR: PRIMARY_COLOR,
  REACT_APP_FUTURE_SECONDARY_COLOR: SECONDARY_COLOR,
  REACT_APP_FUTURE_TITLE: APP_TITLE,
  REACT_APP_FUTURE_MUI_PRO_LICENSE_KEY: MUI_PRO_LICENSE_KEY,
  REACT_APP_FUTURE_AVAILABLE_LANGUAGES: AVAIL_LANGUAGES,
  REACT_APP_FUTURE_PRIMARY_COLOR_DARK: PRIMARY_COLOR_DARK,
  REACT_APP_FUTURE_SECONDARY_COLOR_DARK: SECONDARY_COLOR_DARK,
  REACT_APP_SANDBOX: IS_SANDBOX,
  REACT_APP_FUTURE_AUTH_URL: AUTH_URL,
  REACT_APP_FUTURE_AUTH_REALM: AUTH_REALM,
  REACT_APP_FUTURE_AUTH_CLIENT_ID: AUTH_CLIENT_ID,
  REACT_APP_FUTURE_AUTH_SECRET_KEY: AUTH_SECRET_KEY,
} = envParseRes.data;

export const IS_DEV_ENV = NODE_ENV === 'development';

// additional operations based on env variables

export const ALLOW_THEME_CHANGE =
  !!PRIMARY_COLOR_DARK && !!SECONDARY_COLOR_DARK;
export const { version: VERSION } = pkgJson;

const URL_PARTS_REGEX =
  /^((http[s]?|ftp):\/)?\/?([^:/\s]+)((\/\w+)*\/)([\w\-.]+[^#?\s]+)(.*)?(#[\w-]+)?$/;

const API_URL_PARTS = URL_PARTS_REGEX.exec(API_URL) ?? [];

export const API_URL_HOSTNAME = API_URL_PARTS[3];

if (!IS_DEV_ENV)
  console.debug('BUILD TIME', process.env.REACT_APP_FUTURE_BUILD_TIME);
