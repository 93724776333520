import { useQuery } from '@tanstack/react-query';

import { apiGet } from '../../api/api';
import ApiResourceContract, {
  ApiConverter,
} from '../../api/apiResourceContract';
import { WithId } from '../../utils';

export function singleQueryFn<ApiType, FeType>(
  {
    endpoint,
    signal,
    urlSearchParams,
  }: {
    endpoint: string;
    signal?: AbortSignal;
    urlSearchParams?: string[][];
  },
  convertFn: ApiConverter<ApiType, FeType>
) {
  return apiGet<ApiType, FeType>(
    {
      endpoint,
      signal,
      urlSearchParams: urlSearchParams
        ? new URLSearchParams(urlSearchParams)
        : undefined,
    },
    convertFn
  );
}

function useSingleQuery<
  ApiType,
  FeType extends WithId<{}>,
  CreateResourceApi,
  CreateResourceFe,
  UpdateResourceApi,
  UpdateResourceFe extends WithId<{}>,
  EndpointArgs
>(
  {
    apiResourceContract,
    endpointArgs,
    additionalUrlSearchParams,
  }: {
    apiResourceContract: ApiResourceContract<
      ApiType,
      FeType,
      CreateResourceApi,
      CreateResourceFe,
      UpdateResourceApi,
      UpdateResourceFe,
      EndpointArgs
    >;
    endpointArgs?: EndpointArgs;
    additionalUrlSearchParams?: string[][];
  },
  {
    enabled,
    onSuccess,
    structuralSharing,
    refetchOnWindowFocus,
  }: {
    enabled?: boolean;
    structuralSharing?: boolean;
    onSuccess?: (data: FeType) => void;
    refetchOnWindowFocus?: boolean;
  } = {}
) {
  const { singleEndpoint, apiConverter, listEndpoint } = apiResourceContract;

  const query = useQuery(
    [
      listEndpoint(endpointArgs ?? ({} as EndpointArgs)),
      { id: (endpointArgs as any)?.id },
      { additionalUrlSearchParams },
    ],
    ({ signal }) =>
      singleQueryFn(
        {
          endpoint: singleEndpoint(endpointArgs ?? ({} as EndpointArgs)),
          signal,
          urlSearchParams: additionalUrlSearchParams,
        },
        apiConverter
      ),
    {
      enabled: enabled,
      onSuccess,
      structuralSharing,
      refetchOnWindowFocus,
    }
  );

  return query;
}

export default useSingleQuery;
