export type DbBoolNum = 0 | 1;

export type DbBoolStr = '0' | '1';

export const fromDbBool = (from: DbBoolNum | DbBoolStr) =>
  from === 1 || from === '1';

export const toDbBoolStr = (from: boolean | undefined) =>
  (from === undefined ? undefined : from ? '1' : '0') as DbBoolStr | undefined;

export const toDbBoolNum = (from: boolean) => (from ? 1 : 0) as DbBoolNum;

export const fromStringBool = (str: string) => {
  const temp: { [key: string]: boolean } = {
    true: true,
    True: true,
    false: false,
    False: false,
  };
  return temp[str] as boolean | undefined;
};
